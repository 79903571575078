import Typography from 'widgets/Typography/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { VideoData } from 'types/VideoPreview-types';

function OverallSummaryVersion2({ ProcessDataListForParticipants }: VideoData) {
  return (
    <div style={{ marginTop: 3 }}>
      <Typography label=''>
        <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
          {ProcessDataListForParticipants.length > 0 &&
            ProcessDataListForParticipants[0].overall_summary}{' '}
        </pre>
      </Typography>
    </div>
  );
}
export default OverallSummaryVersion2;
