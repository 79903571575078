/** @format */

import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  useMediaQuery,
  Grid,
  styled,
} from '@mui/material';
import './videoplayerVersion2.css';
import env from 'envConfig';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useAppDispatch, useAppSelector } from 'redux/store';
import Loader from 'widgets/Loader/Loader';
import {
  UserRolesSelectore,
  getEmotionTypeSelector,
} from 'redux/selectors/selectors';
import { GetEmotionTypeListService } from 'services/CommonServices/Emotion-service';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';

import BulbOn from 'icons/BulbOn';
import BulbOff from 'icons/BulbOff';
import TipCascadeBulbAndModal from 'components/VideoPreviewAdvance/TipCascadeBulbAndModal/TipCascadeBulbAndModal';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import CustomSwitchSlider from 'widgets/CustomSwitchSlider/CustomSwitchSlider';
import {
  CoachingSessionReport,
  FlagOption,
  SelectedUserRoleTypes,
  TimeSegment,
} from 'types/VideoPreview-types';
import { UserRoles } from 'types/Dashboard-types';
import Typography from 'widgets/Typography/Typography';

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    backgroundColor: 'white !important', // Set the background color to white
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
      color: '#1206f9', // Set the color to #1206f9
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,

    '&:before': {
      color: '#fff !important', // Set the color to #1206f9 for the before pseudo-element
    },
    '&:after': {
      color: '#1206f9 !important', // Set the color to #1206f9 for the before pseudo-element
    },
  },
}));

interface VideoPlayerProps {
  ProcessDataListForParticipants: Array<CoachingSessionReport>;
  onTimeUpdate: Function;
  videoPlaying: boolean;
  tipsPlaying: string;
  tipStartTime: number;
  videoData: CoachingSessionReport;
  currentVideoTime: number;
  handleVideoTips: Function;
  highlightedIndex: number;
  currentIndex: number;
  selectedFlags: Array<FlagOption>;
  selectedUserRole: Array<UserRoles>;
  folder: string;
  envName: string;
  emoticonSwitch: number;
  tipsSwitch: number;
  vidRef: React.RefObject<HTMLVideoElement>;
  highlightedCardRef: React.MutableRefObject<HTMLDivElement | null>;
  cascadeRef: React.MutableRefObject<HTMLDivElement | null>;
  setVideoPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  setTipStartTime: React.Dispatch<React.SetStateAction<number>>;
  setHighlightedIndex: React.Dispatch<React.SetStateAction<number>>;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
}

const VideoPlayerVersion2 = ({
  ProcessDataListForParticipants,
  onTimeUpdate,

  videoPlaying,
  setVideoPlaying,
  tipsPlaying,
  tipStartTime,
  videoData,
  currentVideoTime,
  handleVideoTips,
  setTipStartTime,
  cascadeRef,
  highlightedCardRef,
  vidRef,
  highlightedIndex,
  setHighlightedIndex,
  currentIndex,
  setCurrentIndex,
  selectedFlags,
  selectedUserRole,
  folder,
  envName,
  emoticonSwitch,
  tipsSwitch,
}: VideoPlayerProps) => {
  const [videoPath, setVideoPath] = useState('');
  const [currentTime, setCurrentTime] = useState(0); // State to track current time
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [selectedColor, setSelectedColor] = useState('All');
  const [selectedSpeaker, setSelectedSpeaker] = useState(0);
  const [currentSegmentIndex, setCurrentSegmentIndex] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isUpdatingProgress, setIsUpdatingProgress] = useState(false);
  const [selectedSpeakerIndex, setSelectedSpeakerIndex] = useState(-1);
  const [aspectRatio, setAspectRatio] = useState(16 / 9);
  const [showEmoji, setShowEmoji] = useState(true);
  const [selectedSpeakerTimeSegments, setSelectedSpeakerTimeSegments] =
    useState([]);
  const [videoEnded, setVideoEnded] = useState(false);
  const [filteredSegments, setFilteredSegments] = useState<Array<TimeSegment>>(
    []
  );

  const attendeesRecord =
    ProcessDataListForParticipants[0]?.attendees_record || [];

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (videoRef.current) {
      const video = videoRef.current;

      video.addEventListener('loadedmetadata', () => {
        const videoAspectRatio = video.videoWidth / video.videoHeight;

        setAspectRatio(videoAspectRatio);
      });
    }
  }, []);

  useEffect(() => {
    if (ProcessDataListForParticipants.length > 0) {
      setVideoPath(ProcessDataListForParticipants[0].processing_event_name);
    }
  }, [ProcessDataListForParticipants, videoPath]);

  useEffect(() => {
    if (videoRef.current) {
      if (tipsPlaying === 'true') {
        videoRef.current.pause();
        setIsPlaying(false);
      } else {
        videoRef.current.play();
        setIsPlaying(true);
      }
    }
  }, [tipsPlaying]);

  useEffect(() => {
    if (videoRef.current) {
      // Set the tip start time as new current time for the video.
      videoRef.current.currentTime = tipStartTime;
      // Set this to be in sync with new time..
      setCurrentTime(tipStartTime);
    }
  }, [tipStartTime]);

  const { isLoadingEmotionType, errorEmotionType, EmotionTypeList } =
    useAppSelector(getEmotionTypeSelector);

  const { isLoadingUserRoles, UserRoles } = useAppSelector(UserRolesSelectore);

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
      onTimeUpdate(videoRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      const newDuration = videoRef.current.duration;
      setDuration(newDuration);
    }
  };

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        setVideoPlaying(false);
      } else {
        videoRef.current.play();
        setVideoPlaying(true);
      }
      setIsPlaying(!isPlaying);
    }
  };
  const handlePauseMainVideo = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        setVideoPlaying(false);
      }
      setIsPlaying(false);
    }
  };

  const getBackgroundColor = (flagId: number, startTime: number): string => {
    if (flagId === 1) {
      return 'red';
    } else if (flagId === 2) {
      return 'yellow';
    } else if (flagId === 3) {
      return 'green';
    }
    return 'gray';
  };

  const getBatteryIcon = (currentEnergyLevel: number): string => {
    if (currentEnergyLevel > 67) {
      return './comonImg/Emoticons-mirro-Default-en/Energy-High.png';
    } else if (currentEnergyLevel > 35) {
      return './comonImg/Emoticons-mirro-Default-en/Energy-Medium.png';
    } else {
      return './comonImg/Emoticons-mirro-Default-en/Energy-Low.png';
    }
  };
  const segments = ProcessDataListForParticipants[0]?.time_segments;

  useEffect(() => {
    const updateSpeakerTimeSegments = () => {
      setFilteredSegments(() => {
        const filteredByFlags = segments.filter((segment: TimeSegment) =>
          selectedFlags.some(
            (selectedFlag: FlagOption) => selectedFlag.id === segment.flag_id
          )
        );

        if (selectedUserRole.length > 0) {
          const newFilteredSegments = selectedUserRole
            .flatMap((role: any) => {
              const selectedAttendee = attendeesRecord[role.index];
              if (selectedAttendee) {
                return filteredByFlags
                  .filter((segment: TimeSegment) =>
                    selectedAttendee.time_segment_list.includes(segment.sno)
                  )
                  .map((segment: TimeSegment) => ({
                    ...segment,
                    updatedValue: 'yourUpdatedValue',
                  }));
              }
              return [];
            })
            .filter((segment) => segment !== null);

          const sortedNewFilteredSegments = newFilteredSegments.sort(
            (a, b) => (a.start_time_stamp || 0) - (b.start_time_stamp || 0)
          );

          return sortedNewFilteredSegments;
        }

        const sortedFilteredByFlags = filteredByFlags.sort(
          (a, b) => (a.start_time_stamp || 0) - (b.start_time_stamp || 0)
        );

        return sortedFilteredByFlags;
      });
    };
    updateSpeakerTimeSegments();
  }, [attendeesRecord, selectedUserRole, selectedFlags, segments]);

  const handlePreviousSegment = () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;

      const eligibleSegments = filteredSegments.filter(
        (segment) => segment.start_time_stamp < currentTime
      );

      eligibleSegments.sort((a, b) => b.start_time_stamp - a.start_time_stamp);

      const closestSegmentIndex = eligibleSegments.findIndex(
        (segment) => segment.start_time_stamp !== currentTime
      );

      if (closestSegmentIndex !== -1) {
        const secondClosestSegment = eligibleSegments[closestSegmentIndex + 1];

        if (secondClosestSegment) {
          const secondClosestSegmentStartTime =
            secondClosestSegment.start_time_stamp;

          videoRef.current.currentTime = secondClosestSegmentStartTime;
        }
      }
    }
  };

  const handleForwardSegment = () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;

      const nextSegment = filteredSegments.find(
        (segment) => segment.start_time_stamp > currentTime
      );

      if (nextSegment) {
        const nextSegmentStartTime = nextSegment.start_time_stamp;

        videoRef.current.currentTime = nextSegmentStartTime;
      }
    }
  };

  const handleProgressDotMouseDown = () => {
    setIsDragging(true);
  };

  const handleProgressDotMouseMove = (event: React.MouseEvent) => {
    if (isDragging && isPlaying && videoRef.current) {
      const progressContainer = event.currentTarget;
      const containerWidth = progressContainer.clientWidth;
      const offsetX =
        event.clientX - progressContainer.getBoundingClientRect().left;
      const newTime = (offsetX / containerWidth) * duration;

      if (!isUpdatingProgress) {
        setIsUpdatingProgress(true);

        setTimeout(() => {
          if (videoRef.current) {
            videoRef.current.currentTime = newTime;
            setCurrentTime(newTime);
          }
          setIsUpdatingProgress(false);
        }, 100);
      }
    }
  };

  const handleProgressDotMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const handleDocumentMouseUp = () => {
      if (isDragging) {
        setIsDragging(false);
      }
    };

    document.addEventListener('mouseup', handleDocumentMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleDocumentMouseUp);
    };
  }, [isDragging]);

  function getEmotionName(emotionId: number): string {
    const matchedEmotion = EmotionTypeList.find(
      (emotion) => emotion.id === emotionId
    );
    return matchedEmotion?.name || '';
  }

  function getSessionIdByAttendeeId(Attendee_Id: number): string {
    const Record =
      ProcessDataListForParticipants[0].attendees_record[Attendee_Id - 1];

    if (!isLoadingUserRoles) {
      const userRole = UserRoles.find(
        (role) => role.id === Record?.session_role_id
      );

      if (userRole) {
        return userRole.name;
      }
    }

    if (ProcessDataListForParticipants[0].session_type_id === 3) {
      return 'Participant';
    } else if (
      ProcessDataListForParticipants[0].session_type_id === 4 ||
      ProcessDataListForParticipants[0].session_type_id === 5
    ) {
      return 'Agent';
    } else if (ProcessDataListForParticipants[0].session_type_id === 7) {
      return 'Interviewer';
    } else {
      return 'Presenter';
    }
  }

  const handleSeek = (event: React.MouseEvent) => {
    if (videoRef.current) {
      const progressContainer = event.currentTarget;
      const containerWidth = progressContainer.clientWidth;
      const offsetX =
        event.clientX - progressContainer.getBoundingClientRect().left;
      const newTime = (offsetX / containerWidth) * duration;

      // Set the new time for the video
      videoRef.current.currentTime = newTime;
      setCurrentTime(newTime);

      // Play the video if it's paused
      if (!isPlaying) {
        videoRef.current.play();
        setIsPlaying(true);
      }
    }
  };
  const storedUserDetails = sessionStorage.getItem('loggedInUserDetails');
  const userDetails = storedUserDetails ? JSON.parse(storedUserDetails) : {};

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowEmoji(event.target.checked);
  };

  const TotalDuration = ProcessDataListForParticipants[0]?.duration_msec;

  function formatTime(timeInMilliseconds: number | null) {
    if (timeInMilliseconds === null) return '00:00';

    const totalSeconds = Math.floor(timeInMilliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
    return formattedTime;
  }

  const totalDuration = formatTime(TotalDuration);

  let baseUrl = '';

  if (envName === 'pd') {
    baseUrl = `https://mirro-personal-pd.gentledune-75b0834d.eastus.azurecontainerapps.io`;
  } else if (envName === 'ci') {
    baseUrl = `https://mirro-personal-ci.proudtree-c5071a26.canadacentral.azurecontainerapps.io`;
  } else if (envName === 'qa') {
    baseUrl = `https://mirro-personal-qa.bluerock-8d9edcb3.canadacentral.azurecontainerapps.io`;
  } else if (envName === 'ua') {
    baseUrl = `https://mirro-personal-ua.whitehill-794c6807.eastus.azurecontainerapps.io`;
  } else {
    baseUrl = `https://mirro-personal-pd.gentledune-75b0834d.eastus.azurecontainerapps.io`;
  }

  return (
    <div>
      {!videoPath ? (
        <Loader />
      ) : (
        <div style={{ maxWidth: '100%' }}>
          <Box
            sx={{
              position: 'relative',
              backgroundImage: 'linear-gradient(to bottom, #000, #232323)',
              width: '100%',
              overflow: 'hidden',
              maxHeight: '60vh',
              height: '60vh',
              '@media (max-width: 768px)': {
                height: '60vh',
              },
              '@media (min-width: 769px) and (max-width: 1024px)': {
                height: '60vh',
              },
            }}
          >
            <video
              ref={videoRef}
              id='myPlayerID'
              width='100%'
              height='100%'
              onClick={handlePlayPause}
              onTimeUpdate={handleTimeUpdate}
              onLoadedMetadata={handleLoadedMetadata}
              onEnded={() => setVideoEnded(true)}
              preload='metadata'
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                objectFit: 'contain',
              }}
              playsInline
            >
              <source
                src={`${baseUrl}/${folder}/${videoPath}`}
                type='video/mp4'
              />
            </video>

            <div
              style={{
                position: 'absolute',
                bottom: '0',
                left: '0',
                width: '100%',
                zIndex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '10px 10px 0 0',
              }}
            >
              <div className='barout'>
                <div className='seeker-bar' onClick={handleSeek}>
                  <div
                    className='time-segments-container'
                    style={{ width: '100%', position: 'relative' }}
                  >
                    {ProcessDataListForParticipants.length > 0 &&
                      filteredSegments.map(
                        (timeSegment: TimeSegment, index: number) => {
                          const segmentColor = getBackgroundColor(
                            timeSegment.flag_id,
                            timeSegment.start_time_stamp
                          );
                          const overallEmotion = timeSegment.emotion;

                          // Display the segment if "All Speakers" is selected or the additional conditions are met
                          if (
                            selectedSpeakerIndex === -1 ||
                            selectedSpeakerTimeSegments.length > 0
                          ) {
                            // Apply flag changes based on the selected color
                            if (
                              selectedColor === 'All' ||
                              selectedColor === segmentColor
                            ) {
                              return (
                                <div
                                  key={index}
                                  className='time-segment'
                                  style={{
                                    left: `${
                                      (timeSegment.start_time_stamp /
                                        duration) *
                                      100
                                    }%`,
                                    width: `${
                                      ((timeSegment.end_time_stamp -
                                        timeSegment.start_time_stamp) /
                                        duration) *
                                      100
                                    }%`,
                                    backgroundColor: segmentColor,
                                  }}
                                >
                                  {showEmoji && emoticonSwitch === 1 ? (
                                    <div className='emojiDiv'>
                                      <img
                                        src={`./comonImg/Emoticons-mirro-Default-en/${overallEmotion}.png`}
                                        alt=''
                                        className='emoticons'
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              );
                            }
                          }

                          return null; // Hide the time segment if it doesn't match the conditions
                        }
                      )}
                  </div>

                  <div
                    className='progress'
                    style={{
                      width: ` 100%`,
                    }}
                    onMouseDown={handleProgressDotMouseDown}
                    onMouseMove={handleProgressDotMouseMove}
                    onMouseUp={handleProgressDotMouseUp}
                  >
                    <div
                      className='progress-dot'
                      style={{
                        left: `${(currentTime / duration) * 100}%`,
                      }}
                    ></div>
                  </div>
                </div>

                <Box
                  className='VideoControlsDiv'
                  sx={{
                    width: '100%',
                    hight: 'auto',
                    zIndex: '2',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={6} md={2}>
                      <Box
                        sx={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignSelf: 'flex-start',
                          alignItems: 'center',
                        }}
                      >
                        {emoticonSwitch === 1 && (
                          <Box
                            sx={{
                              display: 'flex',
                              marginRight: 'auto',
                              left: 0,
                              ml: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                color: '#FFF',
                                textAlign: 'right',
                                fontFamily: 'Poppins',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: 'normal',
                              }}
                              label=''
                            >
                              {`${String(Math.floor(currentTime / 60)).padStart(
                                2,
                                '0'
                              )}:${String(
                                Math.floor(currentTime) % 60
                              ).padStart(2, '0')}`}
                            </Typography>

                            <Typography
                              sx={{
                                color: '#D7D7D7',
                                fontFamily: 'Poppins',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: 'normal',
                              }}
                              label=''
                            >
                              /{totalDuration}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={8}>
                      <Box
                        sx={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          gap: '10px',
                          alignItems: 'center',
                          alignSelf: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            alignSelf: 'center',
                            textAlign: 'center',
                            justifyContent: 'center',
                            gap: '5px',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            onClick={handlePreviousSegment}
                          >
                            <ArrowBackIosIcon sx={{ color: 'white' }} />
                            <Typography
                              sx={{
                                color: '#FFF',
                                textAlign: 'right',
                                fontFamily: 'Poppins',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: 'normal',
                              }}
                              label='videoPreviewAdvance.Prev'
                            />
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {videoEnded ? (
                              <img
                                src='./comonImg/PlayIcon.png'
                                alt='play'
                                className='pauseImg'
                                onClick={handlePlayPause}
                              />
                            ) : (
                              <>
                                {isPlaying ? (
                                  <img
                                    src='./comonImg/PauseIcon.png'
                                    alt='pause'
                                    className='pauseImg'
                                    onClick={handlePlayPause}
                                  />
                                ) : (
                                  <img
                                    src='./comonImg/PlayIcon.png'
                                    alt='play'
                                    className='pauseImg'
                                    onClick={handlePlayPause}
                                  />
                                )}
                              </>
                            )}
                          </Box>

                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            onClick={handleForwardSegment}
                          >
                            <Typography
                              sx={{
                                color: '#FFF',
                                textAlign: 'right',
                                fontFamily: 'Poppins',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: 'normal',
                              }}
                              label='videoPreviewAdvance.Next'
                            />

                            <ArrowForwardIosIcon sx={{ color: 'white' }} />
                          </Box>
                          {tipsSwitch === 1 && (
                            <TipCascadeBulbAndModal
                              videoData={videoData}
                              currentVideoTime={currentVideoTime}
                              ProcessDataListForParticipants={
                                ProcessDataListForParticipants
                              }
                              videoPlaying={videoPlaying}
                              handleVideoTips={handleVideoTips}
                              setTipStartTime={setTipStartTime}
                              handlePauseMainVideo={handlePauseMainVideo}
                              handlePlayPause={handlePlayPause}
                              cascadeRef={cascadeRef}
                              highlightedCardRef={highlightedCardRef}
                              vidRef={vidRef}
                              highlightedIndex={highlightedIndex}
                              setHighlightedIndex={setHighlightedIndex}
                              currentIndex={currentIndex}
                              setCurrentIndex={setCurrentIndex}
                              selectedFlags={selectedFlags}
                              selectedUserRole={selectedUserRole}
                            />
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    {emoticonSwitch === 1 && (
                      <Grid item xs={12} md={2}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            gap: 0.1,
                            justifyContent: 'center',
                          }}
                        >
                          <img
                            src={`./comonImg/Emoticons-mirro-Default-en/Confident.png`}
                            alt=''
                            style={{
                              width: '35px',
                              height: '35px',
                              zIndex: '2',
                            }}
                          />
                          <CustomSwitchSlider
                            checked={showEmoji}
                            onChange={handleSwitchChange}
                          />
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </div>
            </div>
            <Box
              sx={{
                backgroundColor: 'rgba(35, 35, 35, 0.85)',
                zIndex: '2',
                marginLeft: 'auto',
                top: 0,
                mr: 1,
              }}
            >
              {/* Display Emoticon and Battery */}
              {emoticonSwitch === 1 && (
                <Box className='emoticon-battery-container'>
                  {ProcessDataListForParticipants.length > 0 &&
                    filteredSegments.map((data: TimeSegment, index: number) => {
                      const startTime = Math.floor(data.start_time_stamp);
                      const endTime = Math.floor(data.end_time_stamp);

                      if (currentTime >= startTime && currentTime <= endTime) {
                        // Display the corresponding emotion image
                        let energyText;

                        if (data.energy_level > 67) {
                          energyText = 'High';
                        } else if (data.energy_level > 35) {
                          energyText = 'Neutral';
                        } else {
                          energyText = 'Low';
                        }

                        return (
                          <React.Fragment key={index}>
                            <Box
                              sx={{
                                width: '100%',
                                height: 'auto',
                                display: 'flex',
                              }}
                            >
                              <PersonIcon sx={{ color: 'white' }} />
                              <Typography
                                sx={{
                                  color: '#FFF',
                                  textAlign: 'center',
                                  fontFamily: 'Poppins',
                                  fontSize: '12px',
                                  fontStyle: 'normal',
                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                }}
                                label=''
                              >
                                {getSessionIdByAttendeeId(data.attendee_id)}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                width: '100%',
                                height: 'auto',
                                display: 'flex',
                                gap: '20px',

                                padding: '10px',
                              }}
                            >
                              <Box className='emoticonDiv'>
                                <img
                                  src={`./comonImg/Emoticons-mirro-Default-en/${data.emotion}.png`}
                                  alt=''
                                  className='emotionImage'
                                />
                                <Typography
                                  sx={{
                                    color: 'grey',
                                    fontFamily: 'Poppins',
                                    fontSize: '10px',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: 'normal',
                                    letterSpacing: '-0.11px',
                                  }}
                                  label='videoPreviewAdvance.Emotion'
                                />

                                <Typography
                                  sx={{
                                    color: '#FFF',
                                    textAlign: 'center',
                                    fontFamily: 'Poppins',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    lineHeight: 'normal',
                                  }}
                                  label={data.emotion}
                                />
                              </Box>
                              <Box className='batteryDiv'>
                                <img
                                  src={`${getBatteryIcon(data.energy_level)}`}
                                  alt='battery'
                                  className='batteryImg'
                                />
                                <Typography
                                  sx={{
                                    color: 'grey',
                                    fontFamily: 'Poppins',
                                    fontSize: '10px',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: 'normal',
                                    letterSpacing: '-0.11px',
                                  }}
                                  label='videoPreviewAdvance.Energy'
                                />

                                <Typography
                                  sx={{
                                    color: '#FFF',
                                    textAlign: 'center',
                                    fontFamily: 'Poppins',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    lineHeight: 'normal',
                                  }}
                                  label={energyText}
                                />
                              </Box>
                            </Box>
                          </React.Fragment>
                        );
                      } else {
                        return null;
                      }
                    })}
                </Box>
              )}
            </Box>
          </Box>
          {emoticonSwitch === 1 && (
            <div className='indivisualSummary'>
              {ProcessDataListForParticipants.length > 0 &&
                ProcessDataListForParticipants[0].time_segments.map(
                  (data: TimeSegment, index: number) => {
                    const startTime = Math.floor(data.start_time_stamp);
                    const endTime = Math.floor(data.end_time_stamp);

                    if (currentTime >= startTime && currentTime <= endTime) {
                      // Display the corresponding emotion image
                      return (
                        <React.Fragment key={index}>
                          <div className='timeDurationDiv'>
                            <Typography label=''>
                              {Math.floor(startTime)} TO {Math.floor(endTime)}
                            </Typography>
                            <Typography label='videoPreviewAdvance.Duration'>
                              : {Math.floor(endTime - startTime)}
                            </Typography>
                          </div>
                          <div className='emoticonDiv'>
                            <div className='centeredContent'>
                              <img
                                src={`./comonImg/Emoticons-mirro-Default-en/${data.emotion}.png`}
                                alt=''
                                className='summaryEmoji'
                              />
                              <Typography label={data.emotion}></Typography>
                            </div>
                          </div>
                          <div className='batteryDiv'>
                            <img
                              src={`${getBatteryIcon(data.energy_level)}`}
                              alt='battery'
                              className='batteryImg'
                            />
                          </div>
                        </React.Fragment>
                      );
                    } else {
                      return null;
                    }
                  }
                )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VideoPlayerVersion2;
