/** @format */

import Features from 'components/Features/Features';
import { Billing } from 'pages/Billing';
import { Dashboard } from 'pages/Dashboard';
import { Feedback } from 'pages/Feedback';
import { Gallery } from 'pages/Gallery';
import { Help } from 'pages/Help';
import { Learnings } from 'pages/Learnings';
import { Record } from 'pages/Record';
import { SignIn } from 'pages/SignIn';
import { SignUp } from 'pages/SignUp';
import { Subscriptions } from 'pages/Subscriptions';
import { Upload } from 'pages/Upload';
import { VideoPreviewAdvance } from 'pages/VideoPreviewAdvance';
import { ProtectedRoutes, OpenRoutes, AdminRoutes } from './routes';
import { Profile } from 'pages/AccountSettings';
import { StripeSubscriptionPaymentSuccess } from 'pages/StripeSubscriptionPaymentSuccess';
import { StripeSubscriptionPaymentFail } from 'pages/StripeSubscriptionPaymentFail';
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword';
import ForgotPasswordLink from 'components/ForgotPassword/ForgotPasswordLink';
import GenericSuccess from 'components/SignUp/SignUpSuccess/SignUpSuccess';
import IFrameVideoPreviewAdvance from 'components/IFrameControl/IFrameVideoPreviewAdvance';
import IFrameDashboardComponent from 'components/IFrameDashboard/Dashboard';
import { MyAssessments } from 'pages/MyAssessments';
import CandidateAssessment from 'components/CandidateAssessment/CandidateAssessment';
import SendAssessments from 'pages/SendAssessments/SendAssessments';
import DRCreateJobAssessmentComponent from 'pages/JobCreationForm/JobCreationForm';
import DRDashboard from 'components/DeepRecruiter/DRDashboard/DRDashboard';
import DCsignup from 'components/DeepCandidate/DCSignUp/DCSignup';
import DCSignIn from '../components/DeepCandidate/DCSignIn/DCSingin';
import SandboxUploadComponent from 'components/MirroSandbox/SandboxUpload/SandboxUpload';
import SandboxVideoPreview from 'components/MirroSandbox/SandBoxVideoPreview';
import SandboxGallery from 'components/MirroSandbox/SandboxGallery/SandboxGallery';
import VisitorValidation from 'components/MirroSandbox/VisitorValidation/VisitorValidation';

export const arrayRoutes = [
  {
    path: ProtectedRoutes.feedback,
    exact: true,
    component: Feedback,
    type: 'private',
  },
  {
    path: OpenRoutes.signInRoute,
    exact: true,
    component: SignIn,
    type: 'open',
  },
  {
    path: OpenRoutes.forgotPasswordRoute,
    exact: true,
    component: ForgotPassword,
    type: 'open',
  },
  {
    path: OpenRoutes.UpdateForgottenPasswordRoute,
    exact: true,
    component: ForgotPasswordLink,
    type: 'open',
  },
  {
    path: OpenRoutes.signUpRoute,
    exact: true,
    component: SignUp,
    type: 'open',
  },
  {
    path: OpenRoutes.signUpSuccessPage,
    exact: true,
    component: GenericSuccess,
    type: 'open',
  },
  {
    path: ProtectedRoutes.helpRoute,
    exact: true,
    component: Help,
    type: 'private',
  },
  {
    path: ProtectedRoutes.subscriptionsRoute,
    exact: true,
    component: Subscriptions,
    type: 'private',
  },
  {
    path: ProtectedRoutes.galleryRoute,
    exact: true,
    component: Gallery,
    type: 'private',
  },
  {
    path: ProtectedRoutes.learningsRoute,
    exact: true,
    component: Learnings,
    type: 'private',
  },
  {
    path: ProtectedRoutes.recordRoute,
    exact: true,
    component: Record,
    type: 'private',
  },
  {
    path: ProtectedRoutes.dashboardRoute,
    exact: true,
    component: Dashboard,
    type: 'private',
  },
  {
    path: ProtectedRoutes.uploadRoute,
    exact: true,
    component: Upload,
    type: 'private',
  },
  {
    path: ProtectedRoutes.videoPreviewAdvance,
    exact: true,
    component: VideoPreviewAdvance,
    type: 'private',
  },
  {
    path: ProtectedRoutes.profileRoute,
    exact: true,
    component: Profile,
    type: 'private',
  },
  {
    path: ProtectedRoutes.StripeSubscriptionPaymentSuccess,
    exact: true,
    component: StripeSubscriptionPaymentSuccess,
    type: 'private',
  },
  {
    path: ProtectedRoutes.StripeSubscriptionPaymentFail,
    exact: true,
    component: StripeSubscriptionPaymentFail,
    type: 'private',
  },
  {
    path: AdminRoutes.featureRoute,
    exact: true,
    component: Features,
    type: 'admin',
  },
  {
    path: AdminRoutes.billingRoute,
    exact: true,
    component: Billing,
    type: 'admin',
  },
  {
    path: OpenRoutes.IframeVideoControlRoute,
    exact: true,
    component: IFrameVideoPreviewAdvance,
    type: 'open',
  },
  {
    path: OpenRoutes.IframeDashboardRoute,
    exact: true,
    component: IFrameDashboardComponent,
    type: 'open',
  },
  {
    path: ProtectedRoutes.myAssessmentsRoute,
    exact: true,
    component: MyAssessments,
    type: 'private',
  },
  {
    path: ProtectedRoutes.SendAssessmentsRoute,
    exact: true,
    component: SendAssessments,
    type: 'private',
  },
  {
    path: OpenRoutes.candidateAssessmentRoute,
    exact: true,
    component: CandidateAssessment,
    type: 'open',
  },

  {
    path: OpenRoutes.MirroSandboxRoute,
    exact: true,
    component: VisitorValidation,
    type: 'open',
  },
  {
    path: ProtectedRoutes.SandboxVideoPreview,
    exact: true,
    component: SandboxVideoPreview,
    type: 'preview',
  },
  {
    path: ProtectedRoutes.SandboxGallery,
    exact: true,
    component: SandboxGallery,
    type: 'preview',
  },
  // {
  //   path: '/DRCreateJobAssessment',
  //   exact: true,
  //   component: DRCreateJobAssessmentComponent,
  //   type: 'private',
  // },
  // {
  //   path: '/DRDashboard',
  //   exact: true,
  //   component: DRDashboard,
  //   type: 'private',
  // },
  // {
  //   path: '/DCSignup',
  //   exact: true,
  //   component: DCsignup,
  //   type: 'open',
  // },
  // {
  //   path: '/DCSignin',
  //   exact: true,
  //   component: DCSignIn,
  //   type: 'open',
  // },
];

// export const deepRecruiterRoutes = [
//   {
//     path: '/DRCreateJobAssessment',
//     exact: true,
//     component: DRCreateJobAssessmentComponent,
//     type: 'private',
//   },
//   {
//     path: '/DRDashboard',
//     exact: true,
//     component: DRDashboard,
//     type: 'private',
//   },
// ];

// export const deepCandidateRoutes = [
//   {
//     path: '/DCSignup',
//     exact: true,
//     component: DCsignup,
//     type: 'open',
//   },
//   {
//     path: '/DCSignin',
//     exact: true,
//     component: DCSignIn,
//     type: 'open',
//   },
// ];
