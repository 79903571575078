/** @format */
import {
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useMediaQuery,
} from '@mui/material';
import axios, { AxiosProgressEvent, CancelTokenSource } from 'axios';
import DragDropIcon from 'icons/DragDropIcon';
import React, { useEffect, useRef, useState } from 'react';
import { uploadMultipleFilesSelector } from 'redux/selectors/selectors';
import { useAppSelector } from 'redux/store';
import { ProtectedUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import CircularProgressWithLabel from 'widgets/CircularProgressWithLabel/CircularProgressWithLabel';
import { Toast } from 'widgets/Toast/Toast';
import CloseIcon from '@mui/icons-material/Close';
import { isMobile } from 'react-device-detect';
import UploadStatusTableMobileView from './UploadStatusTableMobileView';
import UploadStatusTableDesktopView from './UploadStatusTableDesktopView';
import { useTranslation } from 'react-i18next';
import { removeFilesFromArray } from 'utils/removeFilesFromArray';
import { useFiles } from 'hooks/usefiles';
import Typography from 'widgets/Typography/Typography';
import { History } from 'history';
import { useHistory } from 'react-router-dom';
import { OpenRoutes, ProtectedRoutes } from 'routes/routes';
import DragDropIconDemoSandoBoxPreview from 'icons/DragDropIconDemoSandoBoxPreview';
import { use } from 'i18next';

function UploadWithDragDrop({
  apiKey,
  env,
  setIsUploading,
}: {
  apiKey: string;
  env: string;
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();
  const maxFiles = 1;
  const maxSizeMB = 50;
  const $input = useRef<HTMLInputElement | null>(null);
  const [isHoverDropzone, setIsHoverDropzone] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  const [fileListProgress, setFileListProgress] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(0);
  const [totalCancelUploads, setTotalCancelUploads] = useState(0);
  const [over, setover] = useState(false);
  const [files, setfiles] = useFiles({ maxFiles });
  const [cancelTokenSources, setCancelTokenSources] = useState<
    CancelTokenSource[]
  >([]);
  const [coaching_session_reports_id, setcoaching_session_reports_id] =
    useState<number>(-1);
  const { uploadMultipleFilesError } = useAppSelector(
    uploadMultipleFilesSelector
  );
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');
  const history = useHistory();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    if (totalCancelUploads) {
      setIsUploading(false);
    }
  }, [totalCancelUploads]);

  const uploadFile = async (
    file: File,
    index: number,
    cancelTokenSource: CancelTokenSource
  ): Promise<void> => {
    const formData = new FormData();
    formData.append('files', file);

    let apiUrl = '';

    if (env === 'pd') {
      apiUrl = `${
        process.env.REACT_APP_API_BASE_URL_PD
      }/${ProtectedUrls.uploadSingleFile.url(3)}`;
    } else if (env === 'ci') {
      apiUrl = `${
        process.env.REACT_APP_API_BASE_URL
      }/${ProtectedUrls.uploadSingleFile.url(3)}`;
    } else if (env === 'qa') {
      apiUrl = `${
        process.env.REACT_APP_API_BASE_URL_QA
      }/${ProtectedUrls.uploadSingleFile.url(3)}`;
    } else if (env === 'ua') {
      apiUrl = `${
        process.env.REACT_APP_API_BASE_URL_UA
      }/${ProtectedUrls.uploadSingleFile.url(3)}`;
    } else {
      apiUrl = `${
        process.env.REACT_APP_API_BASE_URL_PD
      }/${ProtectedUrls.uploadSingleFile.url(3)}`;
    }

    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'x-api-key': apiKey,
        },
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          setIsUploading(true);
          const percentCompleted = progressEvent.total
            ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
            : 0;

          const updatedFiles = [...files];
          updatedFiles[index].progress = percentCompleted;
          setfiles(updatedFiles);
        },
        cancelToken: cancelTokenSource.token,
      });
      const jobIdArray = response.data.Job_Id;
      if (jobIdArray && jobIdArray.length > 0) {
        const jobIdObject = jobIdArray[0];
        const fileName = Object.keys(jobIdObject)[0];
        const coachingSessionReportsId =
          jobIdObject[fileName].coaching_session_reports_id;

        setcoaching_session_reports_id(coachingSessionReportsId);
      }

      setUploadSuccess((prev: number) => prev + 1);
    } catch (error) {
      if (axios.isCancel(error)) {
        setTotalCancelUploads((prev) => prev + 1);
      } else {
      }
    }
  };

  function redirectToPreview() {
    let urlPath;
    if (coaching_session_reports_id !== -1) {
      const dataToEncode = {
        apiKey: apiKey,
        coaching_session_reports_id: coaching_session_reports_id,
        env: env,
      };

      const encodedData = btoa(JSON.stringify(dataToEncode));

      localStorage.setItem(
        'coaching_session_reports_id',
        JSON.stringify(coaching_session_reports_id)
      );

      urlPath = `${ProtectedRoutes.SandboxVideoPreview}?id=${encodeURIComponent(
        encodedData
      )}`;

      history.push(urlPath);
    }
  }

  const handleUpload = async () => {
    const promises: Promise<void>[] = [];

    files.forEach((file: any, index: number) => {
      promises.push(uploadFile(file, index, cancelTokenSources[index]));
    });

    try {
      await Promise.all(promises);
    } catch (error) {}
  };

  const handleCancel = (index: number) => {
    if (cancelTokenSources[index]) {
      cancelTokenSources[index].cancel(
        `Upload for file ${files[index].name} canceled by user`
      );
    }
    const updatedFiles = [...files];
    updatedFiles[index].isCanceled = true;
    setfiles(updatedFiles);
  };

  function handleCLoseUploadProgress() {
    setfiles([]);
    setCancelTokenSources([]);
    setApiCalled(false);
    setUploadSuccess(0);
    setTotalCancelUploads(0);
    setFileListProgress(false);
  }

  const handleRetry = async (index: number) => {
    const updatedFiles = [...files];
    updatedFiles[index].isCanceled = false;
    setfiles(updatedFiles);
    const newCancelTokenSource = axios.CancelToken.source();
    const updatedCancelTokenSources = [...cancelTokenSources];
    updatedCancelTokenSources[index] = newCancelTokenSource;
    setCancelTokenSources(updatedCancelTokenSources);

    setTotalCancelUploads((prev) => prev - 1);
    await uploadFile(files[index], index, newCancelTokenSource);
  };

  const filterFilesBySize = (fileList: FileList) => {
    const validFiles = [];
    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].size <= maxSizeMB * 1024 * 1024) {
        validFiles.push(fileList[i]);
      } else {
        Toast('File exceeds the 50 MB limit.', 'error');
      }
    }
    return validFiles;
  };

  useEffect(() => {
    if (files.length > 0 && !apiCalled) {
      setApiCalled(true);
      setFileListProgress(true);
      handleUpload();
    }
  }, [files]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (
      files.length === uploadSuccess + totalCancelUploads &&
      files.length > 0
    ) {
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            redirectToPreview();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [files, uploadSuccess, totalCancelUploads]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography
        sx={{
          color: '#2C2C2C',
          fontFamily: 'Poppins',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '24px',
          letterSpacing: '-0.198px',
        }}
        label='Try with your own video'
      />
      {fileListProgress && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            margin: '2rem auto',
            border: '1px solid #d4dfff',
            marginBottom: '5%',
            backgroundColor: '#f9f9f9',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
            cursor: 'pointer',
            minHeight: '50vh',
            borderRadius: '25px',
            position: 'relative',
          }}
        >
          {files.length === totalCancelUploads ? (
            <Typography
              sx={{
                color: 'red',
                m: 3,
              }}
              label='upload.AllFilesWereCancelled'
            />
          ) : (
            files.length === uploadSuccess + totalCancelUploads && (
              <Box>
                <Typography
                  sx={{
                    color: '#1206F9',
                    margin: 3,
                    pt: isMobile || isTablet ? 5 : 0,
                  }}
                  label=''
                >
                  Redirecting in {countdown} seconds...
                </Typography>
              </Box>
            )
          )}
          <Button
            sx={{ position: 'absolute', right: 0, top: 15 }}
            onClick={handleCLoseUploadProgress}
          >
            <CloseIcon />
          </Button>
          {isMobile ? (
            <UploadStatusTableMobileView
              files={files}
              handleCancel={handleCancel}
              uploadMultipleFilesError={uploadMultipleFilesError}
              handleRetry={handleRetry}
              uploadSuccess={uploadSuccess}
              totalCancelUploads={totalCancelUploads}
            />
          ) : (
            <UploadStatusTableDesktopView
              files={files}
              handleCancel={handleCancel}
              uploadMultipleFilesError={uploadMultipleFilesError}
              handleRetry={handleRetry}
            />
          )}
        </Box>
      )}

      {!fileListProgress && (
        <div
          onClick={() => {
            if ($input.current) {
              $input.current.click();
            }
          }}
          onDrop={(e) => {
            e.preventDefault();
            e.persist();
            const updatedFiles = [...files, ...e.dataTransfer.files];

            setfiles(updatedFiles);

            const newFiles = Array.from(e.dataTransfer.files || []);

            const newCancelTokenSources = newFiles.map(() =>
              axios.CancelToken.source()
            );
            setCancelTokenSources((prevSources) => [
              ...prevSources,
              ...newCancelTokenSources,
            ]);
            setover(false);
          }}
          onDragOver={(e) => {
            e.preventDefault();
            setover(true);
          }}
          onDragLeave={(e) => {
            e.preventDefault();
            setover(false);
          }}
          onMouseEnter={() => setIsHoverDropzone(true)}
          onMouseLeave={() => setIsHoverDropzone(false)}
          className={
            over
              ? 'upload-container over uploader'
              : 'upload-container uploader'
          }
          style={{
            width: '100%',
            minHeight: '200px',
            border: '1px dashed black',
            borderRadius: 5,
            padding: 3,
            background: '#FFF',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'center',
          }}
        >
          <div>
            <DragDropIconDemoSandoBoxPreview />
          </div>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              label='Drag your file or'
              sx={{ fontSize: '14px', fontWeight: 400 }}
            />
            <Button
              variant='text'
              sx={{ color: '#1906F9', fontSize: '14px', fontWeight: 600 }}
            >
              Browse
            </Button>
          </Box>
          <Typography
            sx={{
              fontFamily: 'poppins',
              fontSize: '14px',
              fontWeight: 400,
              color: '#6D6D6D',
            }}
            label='Max 50 MB files are allowed'
          />
          <Typography
            sx={{
              fontFamily: 'poppins',
              fontSize: '14px',
              fontWeight: 400,
              color: '#6D6D6D',
            }}
            label='Avi /Mp4 /Mp3 /Wav /Mkv /M4a '
          />

          <input
            style={{ display: 'none' }}
            type='file'
            ref={$input}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.files) {
                const newFiles = filterFilesBySize(e.target.files);
                const updatedFiles = [...files, ...newFiles];

                setfiles(updatedFiles);

                const newCancelTokenSources = newFiles.map(() =>
                  axios.CancelToken.source()
                );
                setCancelTokenSources((prevSources) => [
                  ...prevSources,
                  ...newCancelTokenSources,
                ]);
                setover(false);
              }
            }}
            accept='audio/*, video/*'
          />
        </div>
      )}
    </Box>
  );
}

export default UploadWithDragDrop;
