import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepConnector from '@mui/material/StepConnector';
import Typography from 'widgets/Typography/Typography';
import Stack from '@mui/material/Stack';
import {
  Avatar,
  Box,
  IconButton,
  Paper,
  styled,
  useMediaQuery,
} from '@mui/material';
import { CoachingSessionReport } from 'types/VideoPreview-types';
import CustomStepper from './CustomStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
const CustomAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: '#FFCDD2',
  color: '#1306f9',
  width: 'max-content',
  height: 'auto',
  minWidth: '30px',
  minHeight: '25px',
  border: '1px solid #CED7FF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiTypography-root': {
    fontSize: '10px',
    color: 'black',
  },
}));

const CustomStepConnector = styled(StepConnector)({
  root: {
    flex: '1',
    display: 'flex',
    justifyContent: 'center',
  },
  line: {
    borderColor: '#CED7FF',
    borderTopWidth: 3,
    borderRadius: 1,
    width: '100% !important',
    height: '20px !important',
    marginTop: 2,
  },
  active: {
    '& $line': {
      borderColor: '#CED7FF',
      width: '100% !important',
      height: '10px',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#CED7FF',
      width: '100% !important',
      height: '10px',
    },
  },
});

const PromptsPane = (props: {
  Data: Array<CoachingSessionReport>;
  setSelectedPrompt: React.Dispatch<React.SetStateAction<number>>;
  selectedPrompt: number;
}) => {
  const handlePromptSelected = React.useCallback((index: number) => {
    props.setSelectedPrompt(index);
  }, []);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');
  const flexContainerRef: any = React.useRef(null);
  const [isScrollableLeft, setIsScrollableLeft] = React.useState(true);
  const [isScrollableRight, setIsScrollableRight] = React.useState(true);
  React.useEffect(() => {
    const handleScroll = () => {
      if (flexContainerRef.current) {
        setIsScrollableLeft(flexContainerRef.current.scrollLeft > 0);
        setIsScrollableRight(
          flexContainerRef.current.scrollLeft <
            flexContainerRef.current.scrollWidth -
              flexContainerRef.current.offsetWidth
        );
      }
    };

    if (flexContainerRef.current) {
      flexContainerRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (flexContainerRef.current) {
        flexContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);
  const clickLeft = () => {
    if (flexContainerRef.current) {
      flexContainerRef.current.scrollTo({
        left: flexContainerRef.current.scrollLeft - 250,
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const clickRight = () => {
    if (flexContainerRef.current) {
      flexContainerRef.current.scrollTo({
        left: flexContainerRef.current.scrollLeft + 250,
        top: 0,
        behavior: 'smooth',
      });
    }
  };
  return (
    <Paper
      elevation={2}
      sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      <Typography
        sx={{ fontSize: '18px', fontWeight: 600, pl: 3, pt: 3 }}
        label='Prompts'
      />
      <Box
        sx={{
          display: 'flex',
          maxWidth: isMobile || isTablet ? '100vw' : '27vw',
        }}
      >
        <IconButton onClick={clickLeft} disabled={!isScrollableLeft}>
          <KeyboardArrowLeft />
        </IconButton>

        <CustomStepper
          stepperData={props.Data}
          handlePromptSelected={handlePromptSelected}
          selectedPrompt={props.selectedPrompt}
          flexContainerRef={flexContainerRef}
        />
        <IconButton onClick={clickRight} disabled={!isScrollableRight}>
          <KeyboardArrowRight />
        </IconButton>
      </Box>

      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 400,
          pl: 3,
          pb: 3,
          pr: 2,
          fontFamily: 'Poppins',
          lineHeight: '18px',
          letterSpacing: '-0.011em',
          textAlign: 'left',
          color: '#000000',
        }}
        label={props.Data[props.selectedPrompt].prompt_question} //prompt_question
      />
    </Paper>
  );
};

export default PromptsPane;
