/** @format */

import * as React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import { useTranslation } from 'react-i18next';
import Typography from 'widgets/Typography/Typography';

const boldStyle = {
  fontWeight: 'bold',
};
const makeTextBold = (text: string) => <span style={boldStyle}>{text}</span>;
const arr = [
  {
    id: 'panel1',
    summary: 'helpandsupport.HowToMakeMirrosPlayBackOfMyVideosFaster',
    details:
      'helpandsupport.VeryHighResolutionRecordingsTakeTimeToThePlaybackInMirrosTrialVersionSaveYourVideosIn240pMp4FormatForBestPerformanceTheLowerQualityDoesNotImpactMirrosEmotionalAnalysis',
  },
  {
    id: 'panel2',
    summary:
      'helpandsupport.AsAUserIwouldLikeToKnowWhatTheSessionScoresMeanHowAreTheyCalculatedAndHowDoIReadInterpretThem',
    details: [
      'helpandsupport.MirroEvaluatesAnySessionBasedOnTheKeyAttributesScoredByMirroAIEnergyVariationPositivityAndEngagement',
      'helpandsupport.TheScoreCalculationsAndTheirInterpretationsAreAsFollows',
      'helpandsupport.EnergyScore',
      'helpandsupport.HowEnergeticTheSpeakerWasPerceivedBasedOnToneOfVoiceAcrossTheTalkTimeTheHigherTheScoreTheMoreEnergeticTheSpeakerWasPerceived',
      'helpandsupport.VariationScore',
      'helpandsupport.HowMuchVariationInTheToneWasDetectedTheMorevariationInTheToneVoiceMakesAndEngagingSpeechThatCanCaptivateTheAudienceTheLowerVariationScoreIndicatesMonotonousPerformanceWhereAudienceOftenLosesInterest',
      'helpandsupport.PositivityScore',
      'helpandsupport.HowPleasantAndPositiveWasTheSpeakersToneOfVoicePerceivedTheMoreTheSpeakerSpokeInEngagingConfidentAssuringAndExcitedToneTheHigherTheScoreTheScoreLowersIfThereWasFatigueOrStressDetectedInTheVoice',
      'helpandsupport.EngagementScore',
      'helpandsupport.HowEngagingWasTheSpeakerSessionThisScoreIsACombinationOfEnergyScorePositivityScoreAndVariationScoreOfTheSessionOrSpeaker',

      'helpandsupport.WhenASpecificRoleSpeakerInTheSessionIsNotSelectedTheseScoresReflectTheEntireSessionsPerformance',

      'helpandsupport.ToVisualizeOneSpeakerRolesPerformanceFilterTheSessionForThatParticularRoleAndTheScoresWillReflectTheirPerformance',
      'helpandsupport.MoreThan2SpeakersSelectedThePerformanceScoreCanBeShownForTheEntireSessionOrForJustOneUserIfMoreThan2SpeakersRolesAreSelectedThePerformanceScoreDefaultsToSessionScore',
    ],
  },
];

export default function HelpAccordion() {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const { t } = useTranslation();
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      console.log(panel);
      setExpanded(isExpanded ? panel : false);
    };

  const accordionElements = arr.map((item) => (
    <Accordion
      elevation={0}
      expanded={expanded === item.id}
      onChange={handleChange(item.id)}
      key={item.id}
    >
      <AccordionSummary
        expandIcon={
          expanded === item.id ? (
            <DoNotDisturbOnOutlinedIcon />
          ) : (
            <AddCircleOutlineIcon />
          )
        }
        aria-controls={item.id}
        id={item.id}
      >
        <Typography
          sx={{
            color: '#33344B',
            width: '35.0625rem',
            fontFamily: 'Poppins',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '1.375rem' /* 137.5% */,
            padding: '0.6rem',
            marginBottom: '0.2rem',
          }}
          label={item.summary.replace(/''/g, "'")}
        />
      </AccordionSummary>
      <AccordionDetails>
        {Array.isArray(item.details) ? (
          item.details.map((detail, index) => (
            <Typography
              key={index}
              sx={{
                color: detail.includes(
                  'MoreThan2SpeakersSelectedThePerformanceScoreCanBeShownForTheEntireSessionOrForJustOneUserIfMoreThan2SpeakersRolesAreSelectedThePerformanceScoreDefaultsToSessionScore'
                )
                  ? 'red'
                  : '#33344B',
                fontFamily: 'Poppins',
                fontSize: '0.875rem',
                fontStyle: 'normal',
                fontWeight:
                  item.id === 'panel2' &&
                  (detail.includes('helpandsupport.EnergyScore') ||
                    detail.includes('helpandsupport.VariationScore') ||
                    detail.includes('helpandsupport.PositivityScore') ||
                    detail.includes('helpandsupport.EngagementScore'))
                    ? 'bold'
                    : 400,
                lineHeight: 'normal',
                letterSpacing: '-0.00963rem',
                width: '100%',
                marginBottom:
                  item.id === 'panel2' &&
                  (index === item.details.length - 2 ||
                    index === 0 ||
                    index === 1 ||
                    index === 9)
                    ? '0.8rem'
                    : '0',
              }}
              label=''
            >
              {item.id === 'panel2' &&
              (detail.includes('helpandsupport.EnergyScore') ||
                detail.includes('helpandsupport.VariationScore') ||
                detail.includes('helpandsupport.PositivityScore') ||
                detail.includes('helpandsupport.EngagementScore')) ? (
                <>
                  <span
                    style={{
                      marginRight: '0.5rem',
                      display: 'inline-block',
                      width: '0.5rem',
                    }}
                  >
                    {'\u25cb'} {/* ring symbol */}
                  </span>
                  <span style={{ flex: 1 }}>
                    {t(detail).replace(/''/g, "'")}
                  </span>
                  {':'}
                </>
              ) : (
                t(detail).replace(/''/g, "'") +
                (detail.includes(
                  'helpandsupport.TheScoreCalculationsAndTheirInterpretationsAreAsFollows'
                )
                  ? ': '
                  : '')
              )}
            </Typography>
          ))
        ) : (
          <Typography
            sx={{
              color: '#33344B',
              fontFamily: 'Poppins',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              letterSpacing: '-0.00963rem',
              width: '100%',
            }}
            label={item.details.replace(/''/g, "'")}
          />
        )}
      </AccordionDetails>
    </Accordion>
  ));

  return <div style={{ width: '100%' }}>{accordionElements}</div>;
}
