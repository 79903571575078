/** @format */

import React from 'react';
import WebRecord from './WebRecord';
import {
  isIOS,
  isIOS13,
  isIPad13,
  isIPhone13,
  isIPod13,
  isSafari,
} from 'react-device-detect';
import IosRecord from './IosRecord';

function Record() {
  return isIOS || isIOS13 || isIPad13 || isIPhone13 || isIPod13 ? (
    <IosRecord />
  ) : (
    <WebRecord />
  );
}

export default Record;
