import { Avatar, Box, Button, IconButton } from '@mui/material';
import React from 'react';
import { CoachingSessionReport } from 'types/VideoPreview-types';

function CustomStepper({
  stepperData,
  handlePromptSelected,
  selectedPrompt,
  flexContainerRef,
}: {
  stepperData: Array<CoachingSessionReport>;
  handlePromptSelected: (index: number) => void;
  selectedPrompt: number;
  flexContainerRef: any;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        overflowX: 'auto',
        alignItems: 'center',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      ref={flexContainerRef}
    >
      {stepperData.map((item: CoachingSessionReport, index: number) => {
        return (
          <React.Fragment key={index}>
            <IconButton
              size='small'
              onClick={() => handlePromptSelected(index)}
            >
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  fontSize: '14px',
                  fontWeight: 600,
                  bgcolor: item.is_skipped
                    ? '#D9D9D9'
                    : item.confident_tone >= item.stressed_tone &&
                      item.confident_tone >= item.fatigued_tone
                    ? '#DBF3DA'
                    : item.stressed_tone >= item.fatigued_tone
                    ? '#FFD4D4'
                    : '#FCEFAA',
                  border: selectedPrompt === index ? '1.5px solid #1206F9' : '',
                  color:
                    selectedPrompt === index
                      ? item.is_skipped
                        ? '#000000'
                        : item.confident_tone >= item.stressed_tone &&
                          item.confident_tone >= item.fatigued_tone
                        ? '#07CC03'
                        : item.stressed_tone >= item.fatigued_tone
                        ? '#FF0000'
                        : '#FF8A00'
                      : '#000000',
                  boxShadow:
                    selectedPrompt === index
                      ? '0px 2px 8px rgba(18, 6, 249, 0.38)'
                      : '',
                }}
                sizes='small'
              >
                P{index + 1}
              </Avatar>
            </IconButton>
            {index < stepperData.length - 1 && (
              <Box
                sx={{
                  minWidth: '40px',
                  border: '0.5px solid #000000',
                  // height: '2px',
                }}
              ></Box>
            )}
          </React.Fragment>
        );
      })}{' '}
    </Box>
  );
}

export default CustomStepper;
