import { AuthWrapperProps } from 'types/common/Common-types';
import React from 'react';
import { AppBar, Box, Toolbar, styled } from '@mui/material';
const drawerWidth = 20;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  minHeight: '100vh',
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));
export default function SandboxPreviewWrapper({ children }: AuthWrapperProps) {
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position='fixed'
        sx={{
          backgroundColor: '#FFFFFF',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar variant='dense'>
          {' '}
          <img
            src='./comonImg/mirro-logo-with-namee.png'
            width='100px'
            alt='mirro-logo-with-namee'
            id='mirro-logo-with-name'
          />
        </Toolbar>
      </AppBar>

      <Main
        sx={{
          marginTop: 6,
          marginBottom: 6,
          marginLeft: 0,
          backgroundColor: '#F8FBFF',
        }}
      >
        {children}
      </Main>
    </Box>
  );
}
