import Typography from 'widgets/Typography/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { VideoData } from 'types/VideoPreview-types';

function OverallSummaryVersion2({ ProcessDataListForParticipants }: VideoData) {
  return (
    <div>
      <div>
        <Typography label='videoPreviewAdvance.OverallSummary' />
      </div>
      <br />
      <div>
        <Typography label=''>
          {ProcessDataListForParticipants.length > 0 &&
            ProcessDataListForParticipants[0].overall_summary}
        </Typography>
      </div>
    </div>
  );
}
export default OverallSummaryVersion2;
