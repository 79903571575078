import { Box, Button, CircularProgress, Paper, Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import RecorderForPromptsQuestion from './RecorderForPromptsQuestion/RecorderForPromptsQuestion';
import {
  THSType,
  TempPromptQuestionsArrayType,
  UploadStatusType,
} from 'types/MyAssessments-types';
import store, { useAppDispatch, useAppSelector } from 'redux/store';
import {
  allPromptsSectionsStatusSelector,
  deleteProcessVideoV2Selector,
  getPractiseSectionsSelector,
  sectionUploadStatusSelector,
  uploadSectionVideoSelector,
} from 'redux/selectors/selectors';
import Loader from 'widgets/Loader/Loader';
import {
  ReactMediaRecorderRenderProps,
  useReactMediaRecorder,
} from 'react-media-recorder';
import Typography from 'widgets/Typography/Typography';
import DialogModal from 'widgets/Dialog/DialogModal';
import SubmitAssessmentConfirmationDialog from './SubmitAssessmentConfirmationDialog';
import {
  getAllPromptsSectionsStatusServiceApi,
  setSectionUploadStatusServiceApi,
  uploadSectionVideoServiceApi,
} from 'services/MyAssessment-service';
import CircularProgressWithLabel from 'widgets/CircularProgressWithLabel/CircularProgressWithLabel';
import {
  setIsFirstReupload,
  setIsFirstUnskipped,
  setIsFirstVideo,
  setIsLastVideo,
  setTotalDuration,
} from 'redux/slices/MyAssessment-slice';
import UploadedAssessmentIcon from 'icons/UploadedAssessmentIcon';
import SkippedAssessmentIcon from 'icons/SkippedAssessmentIcon';
import { uploadStatusList } from 'constants/MyAssessment';
import { deleteProcessVideoV2Service } from 'services/Gallery-service';
import ConfirmationModal from 'widgets/ConfirmationModal/ConfirmationModal';
import { OpenRoutes, ProtectedRoutes } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { CandidateDetailsType } from 'types/SendAssessments-types';
import { t } from 'i18next';
import AssessmentCompleteIcon from 'icons/AssessmentCompleteIcon';

function PromptsComponent({
  mainRecordingSettings,
  setMainRecordingSettings,
  handleTogglePromptSection,
  isFromCandidateAssessment,
  candidateDetails,
}: {
  mainRecordingSettings: THSType;
  setMainRecordingSettings: Function;
  handleTogglePromptSection: () => void;
  isFromCandidateAssessment: boolean;
  candidateDetails: CandidateDetailsType;
}) {
  const widthForPromptsQuestions = 42;

  const skippedSectionVideoUploadolor = '#D3D3D3';
  const currentlySelectedSectionVideo = '#1206F9';

  const dispatch = useAppDispatch();
  const { isLoadingPractiseSections, practiseSections } = useAppSelector(
    getPractiseSectionsSelector
  );
  const { isDeletingProcessVideo } = useAppSelector(
    deleteProcessVideoV2Selector
  );
  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    previewStream,
    clearBlobUrl,
  }: ReactMediaRecorderRenderProps = useReactMediaRecorder({
    audio: mainRecordingSettings.microphoneDetails,
    video:
      mainRecordingSettings.recordingType.id === 1
        ? mainRecordingSettings.cameraDetails
        : false,
  });
  const { uploadStatus } = useAppSelector(sectionUploadStatusSelector);

  const { isUploadingSectionVideo, errorUploadSectionVideo } = useAppSelector(
    uploadSectionVideoSelector
  );
  const { isLoadingAllPromptsSectionsStatus } = useAppSelector(
    allPromptsSectionsStatusSelector
  );
  const history = useHistory();
  const [skippedQuestions, setSkippedQuestions] = useState<number[]>([]);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [currentlySelectedQuestion, setCurrentlySelectedQuestion] =
    useState<TempPromptQuestionsArrayType>({
      id: -1,
      question: '',
      isCurrentlySelected: false,
      isSkipped: false,
      hint: '',
      isCompleted: false,
      description: '',
      maximum_time_seconds: -1,
      sequence_number: -1,
    });
  const [relatedToJobId, setrelatedToJobId] = useState<number>(-1);
  const [promptQuestionsArray, setPromptQuestionsArray] = useState<
    Array<TempPromptQuestionsArrayType>
  >([]);
  const [isLastVideoUploaded, setIsLastVideoUploaded] = useState(false);

  const [showSubmitAssessmentModal, setShowSubmitAssessmentModal] =
    useState(false);
  const [
    openSubmitAssessmentConfirmationDialog,
    setOpenSubmitAssessmentConfirmationDialog,
  ] = React.useState(false);
  const [totalSeconds, setTotalSeconds] = useState<number>(0);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [sessionDurationFinal, setSessionDurationFinal] = useState<number>(0);
  const [
    successfullyUploadedSectionVideos,
    setSuccessfullyUploadedSectionVideos,
  ] = useState<number[]>([]);
  const [skippedSectionVideos, setskippedSectionVideos] = useState<number[]>(
    []
  );
  const [uploadProgressStatus, setUploadProgressStatus] = useState<{
    [key: string]: number;
  }>({
    0: 0,
  });

  const listRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (selectedQuestionIndex === 0 && currentlySelectedQuestion.isCompleted) {
      dispatch(setIsFirstReupload(true));
    }
  }, [currentlySelectedQuestion.isCompleted, dispatch, selectedQuestionIndex]);

  useEffect(() => {
    if (selectedQuestionIndex === 0 && currentlySelectedQuestion.isSkipped) {
      dispatch(setIsFirstUnskipped(true));
    }
  }, [currentlySelectedQuestion.isSkipped, dispatch, selectedQuestionIndex]);

  const handleNext = async (isLastVideo: boolean) => {
    if (isLastVideo) dispatch(setIsLastVideo(isLastVideo));

    if (mediaBlobUrl) {
      const blob = await fetch(mediaBlobUrl).then((response) =>
        response.blob()
      );

      let currentDataToUpload: TempPromptQuestionsArrayType = {
        ...promptQuestionsArray[selectedQuestionIndex],
      };

      dispatch(
        setSectionUploadStatusServiceApi(
          currentlySelectedQuestion.id,
          uploadStatusList.queued,
          blob,
          currentDataToUpload,
          false
        )
      );
      clearBlobUrl();
      dispatch(setTotalDuration(sessionDurationFinal));

      if (!isUploadingSectionVideo) {
        if (
          successfullyUploadedSectionVideos.includes(
            currentlySelectedQuestion.id
          )
        ) {
          const index = successfullyUploadedSectionVideos.indexOf(
            currentlySelectedQuestion.id
          );
          if (index !== -1) {
            successfullyUploadedSectionVideos.splice(index, 1);
          }
        }

        if (skippedSectionVideos.includes(currentlySelectedQuestion.id)) {
          const index = skippedSectionVideos.indexOf(
            currentlySelectedQuestion.id
          );
          if (index !== -1) {
            skippedSectionVideos.splice(index, 1);
          }
        }

        dispatch(
          uploadSectionVideoServiceApi(
            mainRecordingSettings,
            selectedQuestionIndex === 0,
            sessionDurationFinal,
            clearBlobUrl,
            store.getState().api.sectionUploadStatusReducer.related_to,
            setrelatedToJobId,
            false,
            setSuccessfullyUploadedSectionVideos,
            setskippedSectionVideos,
            selectedQuestionIndex === promptQuestionsArray.length - 1,

            setUploadProgressStatus,
            setIsLastVideoUploaded,
            uploadStatus,
            {
              sectionId: currentlySelectedQuestion.id,
              status: uploadStatusList.queued,
              mediaBlobUrl: blob,
              currentDataToUpload,
            },
            candidateDetails
          )
        );
      }
    }

    let currentDataToUpload: TempPromptQuestionsArrayType = {
      ...promptQuestionsArray[selectedQuestionIndex],
      mediaBlobUrl: mediaBlobUrl,
    };

    if (currentDataToUpload.mediaBlobUrl)
      handleCompletedRecordUpload(currentDataToUpload, isLastVideo);

    if (selectedQuestionIndex < promptQuestionsArray.length - 1) {
      if (!isLastVideo) {
        let nextQuestionIndex = selectedQuestionIndex + 1;
        // while (
        //   skippedQuestions.includes(nextQuestionIndex) &&
        //   nextQuestionIndex < promptQuestionsArray.length
        // ) {
        //   nextQuestionIndex++;
        // }

        const nextQuestion = promptQuestionsArray[nextQuestionIndex];
        setCurrentlySelectedQuestion(nextQuestion);
        setSelectedQuestionIndex(nextQuestionIndex);
      }
      const updatedQuestions = promptQuestionsArray.map((question, index) => {
        return {
          ...question,
          isCompleted:
            index === selectedQuestionIndex && currentDataToUpload.mediaBlobUrl
              ? true
              : question.isCompleted,
        };
      });
      setPromptQuestionsArray(updatedQuestions);
    }
  };

  const handlePrev = async (isLastVideo: boolean) => {
    if (isLastVideo) dispatch(setIsLastVideo(isLastVideo));

    if (mediaBlobUrl) {
      const blob = await fetch(mediaBlobUrl).then((response) =>
        response.blob()
      );

      let currentDataToUpload: TempPromptQuestionsArrayType = {
        ...promptQuestionsArray[selectedQuestionIndex],
      };

      dispatch(
        setSectionUploadStatusServiceApi(
          currentlySelectedQuestion.id,
          uploadStatusList.queued,
          blob,
          currentDataToUpload,
          false
        )
      );
      clearBlobUrl();
      dispatch(setTotalDuration(sessionDurationFinal));

      if (!isUploadingSectionVideo) {
        if (
          successfullyUploadedSectionVideos.includes(
            currentlySelectedQuestion.id
          )
        ) {
          const index = successfullyUploadedSectionVideos.indexOf(
            currentlySelectedQuestion.id
          );
          if (index !== -1) {
            successfullyUploadedSectionVideos.splice(index, 1);
          }
        }
        if (skippedSectionVideos.includes(currentlySelectedQuestion.id)) {
          const index = skippedSectionVideos.indexOf(
            currentlySelectedQuestion.id
          );
          if (index !== -1) {
            skippedSectionVideos.splice(index, 1);
          }
        }

        dispatch(
          uploadSectionVideoServiceApi(
            mainRecordingSettings,
            selectedQuestionIndex === 0,
            sessionDurationFinal,
            clearBlobUrl,
            store.getState().api.sectionUploadStatusReducer.related_to,
            setrelatedToJobId,
            false,
            setSuccessfullyUploadedSectionVideos,
            setskippedSectionVideos,
            selectedQuestionIndex === promptQuestionsArray.length - 1,

            setUploadProgressStatus,
            setIsLastVideoUploaded,
            uploadStatus,
            {
              sectionId: currentlySelectedQuestion.id,
              status: uploadStatusList.queued,
              mediaBlobUrl: blob,
              currentDataToUpload,
            },
            candidateDetails
          )
        );
      }
    }
    let currentDataToUpload: TempPromptQuestionsArrayType = {
      ...promptQuestionsArray[selectedQuestionIndex],
      mediaBlobUrl: mediaBlobUrl,
    };
    if (currentDataToUpload.mediaBlobUrl)
      handleCompletedRecordUpload(currentDataToUpload, isLastVideo);
    if (selectedQuestionIndex > 0) {
      let prevQuestionIndex = selectedQuestionIndex - 1;
      // while (
      //   skippedQuestions.includes(prevQuestionIndex) &&
      //   prevQuestionIndex >= 0
      // ) {
      //   prevQuestionIndex--;
      // }
      const prevQuestion = promptQuestionsArray[prevQuestionIndex];
      setCurrentlySelectedQuestion(prevQuestion);
      setSelectedQuestionIndex(prevQuestionIndex);

      const updatedQuestions = promptQuestionsArray.map((question, index) => {
        return {
          ...question,
          isCompleted:
            index === selectedQuestionIndex && currentDataToUpload.mediaBlobUrl
              ? true
              : question.isCompleted,
        };
      });
      setPromptQuestionsArray(updatedQuestions);
    }
  };

  async function handleCompletedRecordUpload(
    completedSectionData: TempPromptQuestionsArrayType,
    isLastVideo: boolean
  ) {}

  async function handleSkippedDispatch() {
    return new Promise<void>((resolve, reject) => {
      const blob = uploadStatusList.skipped;
      let currentDataToUpload: TempPromptQuestionsArrayType = {
        ...promptQuestionsArray[selectedQuestionIndex],
      };
      dispatch(
        setSectionUploadStatusServiceApi(
          currentlySelectedQuestion.id,
          uploadStatusList.queued,
          blob,
          currentDataToUpload,
          false
        )
      );

      if (!isUploadingSectionVideo)
        dispatch(
          uploadSectionVideoServiceApi(
            mainRecordingSettings,
            selectedQuestionIndex === 0,
            sessionDurationFinal,
            clearBlobUrl,
            store.getState().api.sectionUploadStatusReducer.related_to,
            setrelatedToJobId,
            true,
            setSuccessfullyUploadedSectionVideos,
            setskippedSectionVideos,
            selectedQuestionIndex === promptQuestionsArray.length - 1,

            setUploadProgressStatus,
            setIsLastVideoUploaded,
            uploadStatus,
            {
              sectionId: currentlySelectedQuestion.id,
              status: uploadStatusList.queued,
              mediaBlobUrl: blob,
              currentDataToUpload,
            },
            candidateDetails
          )
        );
      resolve();
    });
  }
  const toggleQuestionSkippedStatus = async (
    currentlySelectedQuestion: TempPromptQuestionsArrayType,
    isLastVideo: boolean
  ) => {
    await handleSkippedDispatch();

    if (isLastVideo) dispatch(setIsLastVideo(isLastVideo));

    const updatedQuestions = promptQuestionsArray.map((question) => {
      if (question.id === currentlySelectedQuestion.id) {
        return {
          ...question,
          isSkipped: !question.isSkipped,
        };
      }
      return question;
    });
    setPromptQuestionsArray(updatedQuestions);

    if (
      !currentlySelectedQuestion.isSkipped &&
      selectedQuestionIndex < promptQuestionsArray.length - 1
    ) {
      const nextQuestionIndex = selectedQuestionIndex + 1;
      const nextQuestion = promptQuestionsArray[nextQuestionIndex];
      setCurrentlySelectedQuestion(nextQuestion);
      setSelectedQuestionIndex(nextQuestionIndex);
    }
    setSkippedQuestions((prevSkipped: number[]) => [
      ...prevSkipped,
      selectedQuestionIndex,
    ]);
  };

  function updateCurrentSelectQuestionChanges() {
    promptQuestionsArray.forEach((question, index) => {
      if (index === selectedQuestionIndex) {
        setCurrentlySelectedQuestion(promptQuestionsArray[index]);
      }
    });
  }

  function handleOpenSubmitAssessmentModal() {
    setShowSubmitAssessmentModal(true);
  }

  const handleDeleteProcessVideo = () => {
    setShowSubmitAssessmentModal(false);
    dispatch(
      deleteProcessVideoV2Service(
        store.getState().api.sectionUploadStatusReducer.related_to,
        handleOpenDeletePromptConfirmation
      )
    );
  };

  function handleCloseSubmitAssessmentModal() {
    setShowSubmitAssessmentModal(false);
    dispatch(
      setSectionUploadStatusServiceApi(
        -1,
        uploadStatusList.clearing,
        '',
        {
          id: -1,
          question: '',
          hint: '',
          isCurrentlySelected: false,
          isSkipped: false,
          mediaBlobUrl: null,
          isCompleted: false,
          description: '',
          maximum_time_seconds: -1,
          sequence_number: -1,
        },
        true
      )
    );
    handleTogglePromptSection();
  }

  const handleClickOpenSubmitAssessmentConfirmationDialog = () => {
    setOpenSubmitAssessmentConfirmationDialog(true);
  };
  const handleCloseSubmitAssessmentConfirmationDialog = () => {
    if (isFromCandidateAssessment) {
      sessionStorage.clear();
      history.push(OpenRoutes.signInRoute);
    }
    setOpenSubmitAssessmentConfirmationDialog(false);
    dispatch(
      setSectionUploadStatusServiceApi(
        -1,
        uploadStatusList.clearing,
        '',
        {
          id: -1,
          question: '',
          hint: '',
          isCurrentlySelected: false,
          isSkipped: false,
          mediaBlobUrl: null,
          isCompleted: false,
          description: '',
          maximum_time_seconds: -1,
          sequence_number: -1,
        },
        true
      )
    );
    handleTogglePromptSection();
  };

  const startTimer = () => {
    if (!isRunning) {
      setIsRunning(true);
    }
  };

  const pauseTimer = () => {
    if (isRunning) {
      setIsRunning(false);
    }
  };

  const resetTimer = () => {
    setSessionDurationFinal(totalSeconds);
    setIsRunning(false);
    setTotalSeconds(0);
  };

  function handlegetAllPromptStatus() {
    setShowSubmitAssessmentModal(false);
    dispatch(
      getAllPromptsSectionsStatusServiceApi(
        relatedToJobId,
        handleClickOpenSubmitAssessmentConfirmationDialog
      )
    );
  }

  function handleStopRecording() {
    stopRecording();
  }
  useEffect(() => {
    if (status === 'recording') {
      startTimer();
    }
    if (status === 'paused') {
      pauseTimer();
    }
    if (status === 'stopped') {
      resetTimer();
    }
  }, [status]);

  useEffect(() => {
    updateCurrentSelectQuestionChanges();
  }, [promptQuestionsArray]);

  useEffect(() => {
    if (practiseSections.length > 0) {
      setPromptQuestionsArray(practiseSections);
      setCurrentlySelectedQuestion(practiseSections[0]);
    }
  }, [practiseSections]);
  useEffect(() => {
    if (listRef.current && listRef.current.children[selectedQuestionIndex]) {
      listRef.current.children[selectedQuestionIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [selectedQuestionIndex]);

  useEffect(() => {
    let timerInterval: NodeJS.Timeout;

    if (isRunning) {
      timerInterval = setInterval(() => {
        setTotalSeconds(totalSeconds + 1);
      }, 1000);
      if (totalSeconds === 600) {
        stopRecording();
      }
    }

    return () => clearInterval(timerInterval);
  }, [isRunning, totalSeconds]);

  useEffect(() => {
    const itemsQueued = store
      .getState()
      .api.sectionUploadStatusReducer.uploadStatus.filter(
        (item: UploadStatusType) => {
          return item.status === uploadStatusList.failed;
        }
      );

    if (itemsQueued.length > 0 && !isUploadingSectionVideo) {
      dispatch(
        uploadSectionVideoServiceApi(
          mainRecordingSettings,
          selectedQuestionIndex === 0,
          store.getState().api.sectionUploadStatusReducer.totalSeconds,
          clearBlobUrl,
          store.getState().api.sectionUploadStatusReducer.related_to,
          setrelatedToJobId,
          itemsQueued[0].mediaBlobUrl === uploadStatusList.skipped
            ? true
            : false,
          setSuccessfullyUploadedSectionVideos,
          setskippedSectionVideos,
          store.getState().api.sectionUploadStatusReducer.isLastVideo,
          setUploadProgressStatus,
          setIsLastVideoUploaded,
          uploadStatus,
          itemsQueued[0],
          candidateDetails
        )
      );
    }
  }, [uploadStatus]);

  const [openChildModalSubmitAssessment, setOpenChildModalSubmitAssessment] =
    useState(false);

  const handleOpenChildModalSubmitAssessment = () => {
    setOpenChildModalSubmitAssessment(true);
  };

  const handleCloseChildModalSubmitAssessment = () => {
    setOpenChildModalSubmitAssessment(false);
  };
  const [openDeletePromptConfirmation, setOpenDeletePromptConfirmation] =
    useState(false);

  const handleOpenDeletePromptConfirmation = () => {
    setOpenDeletePromptConfirmation(true);
  };

  const handleCloseDeletePromptConfirmation = () => {
    handleCloseSubmitAssessmentModal();
    setOpenDeletePromptConfirmation(false);
  };

  useEffect(() => {
    if (
      (successfullyUploadedSectionVideos.length !== 0 ||
        skippedSectionVideos.length !== 0 ||
        promptQuestionsArray.length !== 0) &&
      successfullyUploadedSectionVideos.length + skippedSectionVideos.length ===
        promptQuestionsArray.length
    ) {
      handleOpenSubmitAssessmentModal();
    }
  }, [
    successfullyUploadedSectionVideos,
    skippedSectionVideos,
    promptQuestionsArray,
  ]);

  return isLoadingPractiseSections || isDeletingProcessVideo ? (
    <Loader />
  ) : (
    <Box>
      {practiseSections.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            minHeight: '80vh',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            label={t(
              'MyAssessments.CurrentlyPromptsAreNotAvailableForSelectedAssessmentType'
            )}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button onClick={handleTogglePromptSection} variant='text'>
              {t('MyAssessments.ClickHere')}
            </Button>
            <Typography
              label={t('MyAssessments.ToSelectAnotherAssessmentType')}
            />
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', gap: 4 }}>
          <Paper sx={{ width: `${widthForPromptsQuestions}%` }}>
            <Typography
              sx={{
                color: 'var(--Body-Text, #33344B)',
                fontFamily: 'Poppins',
                fontSize: '22px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '-0.352px',
                p: 3,

                textAlign: 'center',
              }}
              label={t('MyAssessments.Prompts')}
            />

            <Box
              sx={{
                mt: 1,
                height: '70vh',
                overflow: 'scroll',
                scrollbarWidth: 'none',
                '-ms-overflow-style': 'none',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
              ref={listRef}
            >
              {promptQuestionsArray.map((practiseSection) => {
                return (
                  <Button
                    fullWidth
                    sx={{
                      textTransform: 'none',
                      opacity: skippedSectionVideos.includes(
                        practiseSection?.id
                      )
                        ? 0.6
                        : 1,
                    }}
                    disabled={
                      status === 'recording' ||
                      skippedSectionVideos.includes(practiseSection?.id)
                    }
                  >
                    <Paper
                      sx={{
                        p: 3,
                        borderRadius: 3,
                        boxShadow: '0px 2px 4px 0px rgba(18, 6, 249, 0.46)',
                        background:
                          practiseSection.id === currentlySelectedQuestion.id &&
                          !skippedSectionVideos.includes(practiseSection?.id)
                            ? currentlySelectedSectionVideo
                            : skippedSectionVideos.includes(practiseSection?.id)
                            ? skippedSectionVideoUploadolor
                            : '',
                        cursor: 'pointer',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 3,
                        border: `${
                          skippedSectionVideos.includes(practiseSection?.id) &&
                          currentlySelectedQuestion.id === practiseSection?.id
                            ? '1px solid #1206f9'
                            : ''
                        }`,
                      }}
                    >
                      <Box sx={{ display: 'flex', gap: 3 }}>
                        <Typography
                          sx={{
                            color:
                              practiseSection.id ===
                                currentlySelectedQuestion.id &&
                              !skippedSectionVideos.includes(
                                practiseSection?.id
                              )
                                ? '#FFF'
                                : '',
                            fontFamily: 'Poppins',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            letterSpacing: '-0.22px',
                          }}
                          label=''
                        >
                          {practiseSection.sequence_number}.
                        </Typography>
                        <Typography
                          sx={{
                            color:
                              practiseSection.id ===
                                currentlySelectedQuestion.id &&
                              !skippedSectionVideos.includes(
                                practiseSection?.id
                              )
                                ? '#FFF'
                                : '',
                            fontFamily: 'Poppins',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            letterSpacing: '-0.22px',
                            textAlign: 'left',
                          }}
                          label={practiseSection.question}
                        />
                      </Box>
                      {!successfullyUploadedSectionVideos.includes(
                        practiseSection?.id
                      ) &&
                        uploadProgressStatus[practiseSection.id] && (
                          <Tooltip title={t('MyAssessments.Uploading')}>
                            <Box>
                              <Box
                                sx={{
                                  position: 'relative',
                                  display: 'inline-flex',
                                }}
                              >
                                <CircularProgress
                                  sx={{
                                    color:
                                      practiseSection.id ===
                                        currentlySelectedQuestion.id &&
                                      !skippedSectionVideos.includes(
                                        practiseSection?.id
                                      )
                                        ? '#fff'
                                        : '',
                                  }}
                                  variant='determinate'
                                  value={
                                    uploadProgressStatus[practiseSection.id]
                                  }
                                />
                                <Box
                                  sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Typography
                                    variant='caption'
                                    component='div'
                                    color={
                                      practiseSection.id ===
                                        currentlySelectedQuestion.id &&
                                      !skippedSectionVideos.includes(
                                        practiseSection?.id
                                      )
                                        ? '#fff'
                                        : ''
                                    }
                                    label=''
                                  >{`${Math.round(
                                    uploadProgressStatus[practiseSection.id]
                                  )}%`}</Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Tooltip>
                        )}

                      {!successfullyUploadedSectionVideos.includes(
                        practiseSection?.id
                      ) &&
                        !practiseSection.isSkipped && // Check if the section is not skipped
                        !practiseSection.isCompleted && // Check if the section is not completed
                        uploadProgressStatus[practiseSection.id] && (
                          <CircularProgress size={20} />
                        )}
                      {practiseSection.isCompleted &&
                        !successfullyUploadedSectionVideos.includes(
                          practiseSection?.id
                        ) &&
                        !uploadProgressStatus[practiseSection.id] && (
                          <CircularProgress size={20} />
                        )}
                      {successfullyUploadedSectionVideos.includes(
                        practiseSection?.id
                      ) && (
                        <Tooltip title={t('MyAssessments.Uploaded')}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%', // Adjust height if needed
                              width: 'fit-content', // Adjust width if needed
                            }}
                          >
                            <AssessmentCompleteIcon />
                          </Box>
                        </Tooltip>
                      )}
                      {skippedSectionVideos.includes(practiseSection?.id) && (
                        <SkippedAssessmentIcon />
                      )}
                    </Paper>
                  </Button>
                );
              })}
            </Box>
          </Paper>
          <Box
            sx={{
              width: `${100 - widthForPromptsQuestions}%`,
              display: currentlySelectedQuestion.id === -1 ? 'flex' : '',
              justifyContent:
                currentlySelectedQuestion.id === -1 ? 'center' : '',
              alignItems: currentlySelectedQuestion.id === -1 ? 'center' : '',
            }}
          >
            {currentlySelectedQuestion.id === -1 ? (
              <Typography
                label={t('MyAssessments.PleaseSelectPromptToRecord')}
              />
            ) : (
              <RecorderForPromptsQuestion
                currentlySelectedQuestion={currentlySelectedQuestion}
                startRecording={startRecording}
                previewStream={previewStream}
                handleStopRecording={handleStopRecording}
                status={status}
                handleNext={handleNext}
                handlePrev={handlePrev}
                selectedQuestionIndex={selectedQuestionIndex}
                maxLengthofSectionsArray={promptQuestionsArray.length - 1}
                toggleQuestionSkippedStatus={toggleQuestionSkippedStatus}
                mediaBlobUrl={mediaBlobUrl}
                clearBlobUrl={clearBlobUrl}
                setMainRecordingSettings={setMainRecordingSettings}
                mainRecordingSettings={mainRecordingSettings}
                isLastVideoUploaded={isLastVideoUploaded}
                handleOpenSubmitAssessmentModal={
                  handleOpenSubmitAssessmentModal
                }
                isFromCandidateAssessment={isFromCandidateAssessment}
                candidateDetails={candidateDetails}
              />
            )}
          </Box>{' '}
        </Box>
      )}

      <DialogModal
        onClickCancelButton={handleOpenChildModalSubmitAssessment}
        confirmButtonLabel={t('MyAssessments.Yes')}
        cancelButtonLabel={t('MyAssessments.No')}
        dialogModalTitle={t('MyAssessments.SubmitAssessment')}
        dialogModalBody={t(
          'MyAssessments.AreYouSureYouWantToSubmitCurrentAssessmentForMirroAnalysis'
        )}
        openDialogModal={showSubmitAssessmentModal}
        onClickConfirmButton={handlegetAllPromptStatus}
      />

      <DialogModal
        onClickCancelButton={handleCloseChildModalSubmitAssessment}
        onClickConfirmButton={handleDeleteProcessVideo}
        confirmButtonLabel={t('MyAssessments.Yes')}
        cancelButtonLabel={t('MyAssessments.No')}
        dialogModalTitle={''}
        dialogModalBody={t('MyAssessments.AllTheRecordedPromptsWillBeDeleted')}
        openDialogModal={openChildModalSubmitAssessment}
      />
      <SubmitAssessmentConfirmationDialog
        openSubmitAssessmentConfirmationDialog={
          openSubmitAssessmentConfirmationDialog
        }
        handleCloseSubmitAssessmentConfirmationDialog={
          handleCloseSubmitAssessmentConfirmationDialog
        }
        isFromCandidateAssessment={isFromCandidateAssessment}
      />

      <ConfirmationModal
        message={t('MyAssessments.RecordedPromptDeletedSuccessfully')}
        openConfirmationModal={openDeletePromptConfirmation}
        handleCloseConfirmationModal={handleCloseDeletePromptConfirmation}
      />

      {isLoadingAllPromptsSectionsStatus && <Loader />}
      {store.getState().api.sectionUploadStatusReducer.isLastVideo &&
        isUploadingSectionVideo && <Loader />}
    </Box>
  );
}

export default PromptsComponent;
