/** @format */
import { Box } from '@mui/material';
import RecordVideoButtonIcon from 'icons/RecordVideoButtonIcon';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/store';
import { UploadSingleFileService } from 'services/UploadMedia-services';
import Button from 'widgets/CustomButton/Button';
import { removeFilesFromArray } from 'utils/removeFilesFromArray';

function removeItems(arr: any, item: number) {
  for (var i = 0; i < item; i++) {
    arr.pop();
  }
}

function useFiles({
  initialState = [],
  maxFiles,
}: {
  initialState?: any;
  maxFiles: number;
}) {
  const [state, setstate] = useState(initialState);
  function withBlobs(files: any) {
    const destructured = [...files];

    if (destructured.length > maxFiles) {
      const difference = destructured.length - maxFiles;
      removeItems(destructured, difference);
    }
    const blobs = destructured
      .map((file) => {
        file.preview = URL.createObjectURL(file);
        return file;
      })
      .filter((elem) => elem !== null);

    setstate(blobs);
  }
  return [state, withBlobs];
}

function IosRecordVideo({
  videoDesc,
  recordingType,
  selectedSessionType,
  handleOpenRecordedSessionUploadCompleteModal,
}: any) {
  const maxFiles = 1;
  const $input = useRef<HTMLInputElement | null>(null);
  const dispatch = useAppDispatch();
  const [files, setFiles] = useFiles({ maxFiles });

  useEffect(() => {
    if (files.length > 0) {
      handleUpload();
    }
  }, [files]);

  const handleUploadComplete = useCallback(() => {
    setFiles([]);
    handleOpenRecordedSessionUploadCompleteModal();
  }, [handleOpenRecordedSessionUploadCompleteModal]);

  const handleUpload = useCallback(async () => {
    if (files.length > 0) {
      dispatch(
        UploadSingleFileService(
          files[0].preview,
          selectedSessionType.id,
          videoDesc,
          recordingType,
          handleUploadComplete
        )
      );
    }
  }, [
    dispatch,
    files,
    selectedSessionType.id,
    videoDesc,
    recordingType,
    handleUploadComplete,
  ]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '80%',
      }}
    >
      <Button
        sx={{
          padding: 0.5,
          paddingLeft: 2,
          paddingRight: 2,
          borderRadius: 5,
          backgroundColor: '#1206f9',
          fontSize: '10px',
        }}
        variant='contained'
        startIcon={<RecordVideoButtonIcon />}
        className='custom-file-button'
        onClick={() => {
          if ($input.current) {
            $input.current.click();
          }
        }}
        label='record.ClickToOpenCameraAndStartRecording'
      />
      <input
        type='file'
        ref={$input}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const newFiles = Array.from(e.target.files || []);
          setFiles([...files, ...newFiles]);
        }}
        multiple={maxFiles > 1}
        accept='audio/*, video/*'
        capture='user'
        style={{ display: 'none' }}
      />{' '}
    </Box>
  );
}

export default IosRecordVideo;
