/** @format */

import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { Card, CardActionArea } from '@mui/material';
import './tipcascadeversion2.css';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Loader from 'widgets/Loader/Loader';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { GetEmotionTypeListService } from 'services/CommonServices/Emotion-service';
import { getEmotionTypeSelector } from 'redux/selectors/selectors';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  CoachingMoment,
  FlagOption,
  SelectedUserRoleTypes,
  TimeSegment,
} from 'types/VideoPreview-types';
import { GetKnowledgeTipService } from 'services/CommonServices/Knowledgetips-service';
import { KnowledgeTipsSelector } from 'redux/selectors/selectors';
import env from 'envConfig';
import { useTranslation } from 'react-i18next';
import { formatTimeInMinSecs } from 'utils/formatTimeInMinSecs';
import Typography from 'widgets/Typography/Typography';

function TipCascadeVersion2({
  ProcessDataListForParticipants,
  currentVideoTime,
  videoData,
  videoPlaying,
  setVideoPlaying,
  handleVideoTips,
  setTipStartTime,
  cascadeRef,
  highlightedCardRef,
  vidRef,
  highlightedIndex,
  setHighlightedIndex,
  currentIndex,
  setCurrentIndex,
  selectedUserRole,
  selectedFlags,
  selectedPrompt,
}: any) {
  const dispatch = useAppDispatch();

  const [filteredSegments, setFilteredSegments] = useState<any[]>([]);

  const attendeesRecord =
    ProcessDataListForParticipants[selectedPrompt]?.attendees_record || [];

  const { EmotionTypeList } = useAppSelector(getEmotionTypeSelector);

  useEffect(() => {
    if (videoData && Array.isArray(videoData?.time_segments)) {
      const newIndex = videoData.time_segments.findIndex(
        (item: any) =>
          currentVideoTime >= Math.floor(item.start_time_stamp) &&
          currentVideoTime <= Math.floor(item.end_time_stamp)
      );
      setHighlightedIndex(newIndex);
    }
  }, [currentVideoTime, setHighlightedIndex, videoData]);

  useEffect(() => {
    dispatch(GetEmotionTypeListService());
    dispatch(GetKnowledgeTipService(32));
  }, [dispatch]);

  useEffect(() => {
    if (!videoData) {
      return;
    }

    let tempKnowledgeTipsArray: any[] = [];

    for (const coachingMoment of videoData.coaching_moments) {
      const knowledgeTips = coachingMoment.knowledge_tips;

      if (knowledgeTips.length > 0) {
        tempKnowledgeTipsArray = [...tempKnowledgeTipsArray, ...knowledgeTips];

        break;
      }
    }
  }, [videoData]);

  useEffect(() => {
    if (
      highlightedIndex !== null &&
      highlightedCardRef.current &&
      cascadeRef.current
    ) {
      const cardRect = highlightedCardRef.current.getBoundingClientRect();
      const cascadeRect = cascadeRef.current.getBoundingClientRect();
      const scrollYOffset = cardRect.top - cascadeRect.top - 20;
      cascadeRef.current.scrollTo({
        top: cascadeRef.current.scrollTop + scrollYOffset,
        behavior: 'smooth',
      });
    }
  }, [cascadeRef, highlightedCardRef, highlightedIndex]);
  useEffect(() => {
    dispatch(GetEmotionTypeListService());
  }, [dispatch]);

  const segments = useMemo(() => {
    if (
      ProcessDataListForParticipants &&
      ProcessDataListForParticipants[0]?.is_prompt_section
    ) {
      return ProcessDataListForParticipants[0]?.prompt_sections[selectedPrompt]
        ?.time_segments;
    } else {
      return ProcessDataListForParticipants[0]?.time_segments;
    }
  }, [ProcessDataListForParticipants, selectedPrompt]);

  const updateSpeakerTimeSegments = useCallback(() => {
    setFilteredSegments((prevFilteredSegments) => {
      const filteredByFlags: TimeSegment[] = segments.filter(
        (segment: TimeSegment) =>
          selectedFlags.some(
            (selectedFlag: FlagOption) => selectedFlag.id === segment.flag_id
          )
      );

      if (selectedUserRole.length > 0) {
        const newFilteredSegments: TimeSegment[] = (
          selectedUserRole.flatMap((role: SelectedUserRoleTypes) => {
            const selectedAttendee = attendeesRecord[role.index];
            if (selectedAttendee) {
              return filteredByFlags.flatMap((segment: TimeSegment | null) =>
                selectedAttendee.time_segment_list.includes(segment?.sno || 0)
                  ? { ...segment, updatedValue: 'yourUpdatedValue' }
                  : null
              );
            }
            return [];
          }) as (TimeSegment | null)[]
        ).filter(
          (segment: TimeSegment | null): segment is TimeSegment =>
            segment !== null
        );

        const sortedNewFilteredSegments: TimeSegment[] =
          newFilteredSegments.sort(
            (a, b) => (a?.start_time_stamp || 0) - (b?.start_time_stamp || 0)
          );

        return sortedNewFilteredSegments;
      }

      const sortedFilteredByFlags = filteredByFlags.sort(
        (a, b) => a.start_time_stamp - b.start_time_stamp
      );

      return sortedFilteredByFlags;
    });
  }, [segments, selectedUserRole, selectedFlags, attendeesRecord]);

  useEffect(() => {
    if (segments) {
      updateSpeakerTimeSegments();
    }
  }, [segments, selectedPrompt, updateSpeakerTimeSegments]);

  const getEmotionName = useCallback(
    (emotionId: number): string => {
      const matchedEmotion = EmotionTypeList.find(
        (emotion: any) => emotion.id === emotionId
      );
      return matchedEmotion?.name || '';
    },
    [EmotionTypeList]
  );

  useEffect(() => {
    if (currentVideoTime && filteredSegments.length > 0) {
      const newIndex = filteredSegments.findIndex(
        (item: any) =>
          currentVideoTime >= Math.floor(item.start_time_stamp) &&
          currentVideoTime <= Math.floor(item.end_time_stamp)
      );
      setHighlightedIndex(newIndex);
    }
  }, [currentVideoTime, filteredSegments, setHighlightedIndex]);

  const handleTileClick = useCallback(
    (index: any) => {
      if (highlightedIndex === index) {
        return;
      }

      let desiredSegment = filteredSegments[index];
      setTipStartTime(desiredSegment.start_time_stamp);
      if (!videoPlaying) {
        setHighlightedIndex(index);
      }
    },
    [highlightedIndex, filteredSegments, setTipStartTime, videoPlaying]
  );

  const handlePlay = useCallback(() => {
    handleVideoTips('true');
  }, [handleVideoTips]);

  const handleEnd = useCallback(() => {
    handleVideoTips('false');
  }, [handleVideoTips]);

  

  return (
    <div className='cascade cascade-container' ref={cascadeRef}>
      {filteredSegments.map((item: any, index: number) => {
        let coachingMoment;

        if (videoData?.prompt_sections && selectedPrompt) {
          const promptSections = videoData?.prompt_sections[selectedPrompt];
          if (promptSections && promptSections.coaching_moments) {
            coachingMoment = promptSections.coaching_moments.find(
              (moment: CoachingMoment) =>
                moment.delivery_time_stamp === item.start_time_stamp
            );
          }
        } else {
          coachingMoment = videoData?.coaching_moments.find(
            (moment: CoachingMoment) =>
              moment.delivery_time_stamp === item.start_time_stamp
          );
        }

        const handleBackward = () => {
          if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
          }
        };

        const handleForward = () => {
          const nextIndex = currentIndex + 1;
          if (nextIndex < knowledgeTipsWithMedia.length) {
            setCurrentIndex(nextIndex);
          }
        };

        let knowledgeTipsWithMedia;
        if (coachingMoment) {
          knowledgeTipsWithMedia = coachingMoment.knowledge_tips.filter(
            (tip: any) => tip.media_link !== null
          );
        } else {
          knowledgeTipsWithMedia = [];
        }

        const isSingleTipWithMedia = knowledgeTipsWithMedia?.length === 1;
        const disableForward =
          knowledgeTipsWithMedia.length <= 1 ||
          currentIndex >= knowledgeTipsWithMedia.length - 1;
        const disableBackward = currentIndex === 0;

        return (
          <div
            className='card-container'
            key={index}
            onClick={() => handleTileClick(index)}
          >
            <div className='connecting-line'></div>
            <div
              key={index}
              ref={highlightedIndex === index ? highlightedCardRef : null}
              className={`tip-card ${
                highlightedIndex === index ? 'highlighted' : ''
              }`}
            >
              <div
                className={`dot ${index % 2 === 0 ? 'dot-even' : 'dot-odd'}`}
              ></div>
              <div
                onClick={videoPlaying ? undefined : (e) => e.preventDefault()}
                key={index}
                ref={highlightedIndex === index ? highlightedCardRef : null}
                className={`tip-card ${
                  highlightedIndex === index ? 'highlighted' : ''
                }`}
                data-start-time={Math.floor(item.start_time_stamp)}
                data-end-time={Math.floor(item.end_time_stamp)}
              >
                <Card
                  sx={{
                    margin: '20px',
                    width: '100%',
                    borderRadius: '4px',
                    border: '1px solid #D4D2FA',
                    backgroundColor:
                      highlightedIndex === index ? '#FFF' : 'transparent',
                    boxShadow:
                      highlightedIndex === index
                        ? '0px 0px 7px 0px rgba(90, 98, 241, 0.59)'
                        : 'none',
                    padding: '5px',
                  }}
                >
                  <CardActionArea>
                    <Typography
                      sx={{ display: 'inline-flex', marginLeft: '10px' }}
                      label=''
                    >
                      <Typography
                        sx={{
                          color: '#0E4EEC',
                          backgroundColor: '#E2FCFF',
                          width: 'fit-content',
                          height: 'fit-content',
                          paddingLeft: '10px',
                          paddingRight: '10px',
                          borderRadius: '35px',
                        }}
                        label=''
                      >
                        {formatTimeInMinSecs(Math.floor(item.start_time_stamp))}
                      </Typography>
                      {item.flag_id === 1 && (
                        <BookmarkIcon
                          sx={{ marginRight: '5px', color: 'red' }}
                          className='rotate-icon'
                        />
                      )}
                      {item.flag_id === 2 && (
                        <BookmarkIcon
                          sx={{ marginRight: '5px', color: 'yellow' }}
                          className='rotate-icon'
                        />
                      )}
                      {item.flag_id === 3 && (
                        <BookmarkIcon
                          sx={{ marginRight: '5px', color: 'green' }}
                          className='rotate-icon'
                        />
                      )}
                      {getEmotionName(item.emotion_id)}
                    </Typography>
                    {highlightedIndex === index && (
                      <div>
                        <Typography
                          variant='h6'
                          sx={{
                            color: '#2C2C2C',
                            fontSize: '12px',
                            fontStyle: 'bold',
                            fontWeight: 900,
                            lineHeight: 'normal',

                            margin: '5%',
                          }}
                          label='videoPreviewAdvance.Suggestions'
                        />

                        <Typography
                          sx={{ margin: '10px' }}
                          label={item.time_segment_label}
                        />

                        {coachingMoment &&
                          coachingMoment.knowledge_tips.length > 0 && (
                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  marginTop: '10px',
                                  width: '100%',
                                  gap: '5px',
                                }}
                              >
                                <p className='tipsText'>
                                  <Typography label='videoPreviewAdvance.MirroExpertTips' />
                                </p>
                                <Typography
                                  sx={{
                                    color: '#2C2C2C',
                                    fontFamily: 'Poppins',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    lineHeight: 'normal',
                                    letterSpacing: '-0.132px',
                                  }}
                                  label=''
                                >
                                  {isSingleTipWithMedia
                                    ? '1/1'
                                    : `${currentIndex + 1}/${
                                        knowledgeTipsWithMedia.length
                                      }`}
                                </Typography>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginTop: '10px',
                                  width: '100%',
                                  gap: '10px',
                                }}
                              >
                                <span
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent the click event from propagating
                                    handleBackward();
                                  }}
                                  className={`arrow-icon ${
                                    disableBackward ? 'disabled' : ''
                                  }`}
                                >
                                  <ArrowBackIosIcon />
                                </span>
                                <div>
                                  {knowledgeTipsWithMedia[currentIndex] &&
                                  knowledgeTipsWithMedia[
                                    currentIndex
                                  ].media_link.endsWith('.gif') ? (
                                    <img
                                      src={`${env.REACT_APP_BASE_URL}/${knowledgeTipsWithMedia[currentIndex].media_link}`}
                                      alt=''
                                      className='gifPlayer'
                                    />
                                  ) : (
                                    <video
                                      ref={vidRef}
                                      controls
                                      className='tipsPlayer'
                                      onPlay={handlePlay}
                                      onEnded={handleEnd}
                                      onPause={handleEnd}
                                      src={
                                        knowledgeTipsWithMedia[currentIndex]
                                          ? `${env.REACT_APP_BASE_URL}/${knowledgeTipsWithMedia[currentIndex].media_link}`
                                          : ''
                                      }
                                    ></video>
                                  )}
                                </div>
                                <span
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent the click event from propagating
                                    handleForward();
                                  }}
                                  className={`arrow-icon ${
                                    disableForward ? 'disabled' : ''
                                  }`}
                                >
                                  <ArrowForwardIosIcon />
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                    )}
                  </CardActionArea>
                </Card>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default TipCascadeVersion2;
