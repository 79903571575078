import { createSlice } from '@reduxjs/toolkit';
import {
  ProcessDataListForParticipantsVersion2InitialStateTypes,
  getProcessStatusInitialStateTypes,
  ProcessStatus,
  updateVideoTitleInitialStateTypes,
} from 'types/VideoPreview-types';

const ProcessDataListForParticipantsVersion2InitialState: ProcessDataListForParticipantsVersion2InitialStateTypes =
  {
    isLoadingProcessDataListForParticipantsVersion2: false,
    errorProcessDataListForParticipantsVersion2: '',
    ProcessDataListForParticipantsVersion2: [],
  };

const UpdateVideoTitleInitialState: updateVideoTitleInitialStateTypes = {
  isLoadingUpdateVideoTitle: false,
  errorUpdatingVideoTitle: '',
  updateVideoTitle: {
    coaching_session_reports_id: -1,
    video_title: '',
  },
};

const processStatusInitialState: getProcessStatusInitialStateTypes = {
  isLoadingProcessStatus: false,
  errorProcessStatus: '',
  processStatus: [],
};

const getProcessDataListForParticipantsVersion2Slice = createSlice({
  name: 'api',
  initialState: ProcessDataListForParticipantsVersion2InitialState,
  reducers: {
    ProcessDataListForParticipantVersion2Load(state) {
      state.isLoadingProcessDataListForParticipantsVersion2 = true;
    },
    ProcessDataListForParticipantVersion2Success(state, action) {
      state.isLoadingProcessDataListForParticipantsVersion2 = false;
      state.ProcessDataListForParticipantsVersion2 = action.payload;
    },
    ProcessDataListForParticipantVersion2Failure(state, action) {
      state.isLoadingProcessDataListForParticipantsVersion2 = false;
      state.errorProcessDataListForParticipantsVersion2 = action.payload;
    },
  },
});

export const {
  ProcessDataListForParticipantVersion2Load,
  ProcessDataListForParticipantVersion2Success,
  ProcessDataListForParticipantVersion2Failure,
} = getProcessDataListForParticipantsVersion2Slice.actions;
export { getProcessDataListForParticipantsVersion2Slice };

const updateVideoTitleSlice = createSlice({
  name: 'api',
  initialState: UpdateVideoTitleInitialState,
  reducers: {
    updateVideoTitleLoad(state) {
      state.isLoadingUpdateVideoTitle = true;
    },
    updateVideoTitleSuccess(state, action) {
      state.isLoadingUpdateVideoTitle = false;
      state.updateVideoTitle = action.payload;
    },
    updateVideoTitleFailure(state, action) {
      state.isLoadingUpdateVideoTitle = false;
      state.errorUpdatingVideoTitle = action.payload;
    },
  },
});

export const {
  updateVideoTitleLoad,
  updateVideoTitleFailure,
  updateVideoTitleSuccess,
} = updateVideoTitleSlice.actions;

export { updateVideoTitleSlice };

const getProcessStatusSlice = createSlice({
  name: 'api',
  initialState: processStatusInitialState,
  reducers: {
    processStatusLoad(state) {
      state.isLoadingProcessStatus = true;
    },
    processStatusSuccess(state, action) {
      state.isLoadingProcessStatus = false;
      state.processStatus = action.payload;
    },
    processStatusFailure(state, action) {
      state.isLoadingProcessStatus = false;
      state.errorProcessStatus = action.payload;
    },
  },
});

export const { processStatusLoad, processStatusFailure, processStatusSuccess } =
  getProcessStatusSlice.actions;

export { getProcessStatusSlice };
const UpdateVideoTitleForIframeInitialState = {
  isLoadingUpdateVideoTitleForIframe: false,
  videoTitleForIframe: [],
  errorUpdateVideoTitleForIframe: '',
};

const handleUpdateVideoTitleForIframeSlice = createSlice({
  name: 'handleUpdateVideoTitleForIframeSlice',
  initialState: UpdateVideoTitleForIframeInitialState,
  reducers: {
    UpdateVideoTitleForIframeLoad(state) {
      state.isLoadingUpdateVideoTitleForIframe = true;
    },
    UpdateVideoTitleForIframeSuccess(state, action) {
      state.isLoadingUpdateVideoTitleForIframe = false;
      state.videoTitleForIframe = action.payload;
    },
    UpdateVideoTitleForIframeFailure(state, action) {
      state.isLoadingUpdateVideoTitleForIframe = false;
      state.errorUpdateVideoTitleForIframe = action.payload;
    },
  },
});
export const {
  UpdateVideoTitleForIframeLoad,
  UpdateVideoTitleForIframeSuccess,
  UpdateVideoTitleForIframeFailure,
} = handleUpdateVideoTitleForIframeSlice.actions;

export { handleUpdateVideoTitleForIframeSlice };
