import React, { useState } from 'react';
import { CoachingSessionReport } from 'types/VideoPreview-types';
import { Box, Grid, Paper, Button as MuiButton } from '@mui/material';
import Typography from 'widgets/Typography/Typography';
import { PerformanceStatGraph } from 'widgets/PerformanceStatGraph';
import { ToneStatGraph } from 'widgets/ToneStatGraph';
import { usePDF } from 'react-to-pdf';
import Button from 'widgets/CustomButton/Button';
import CloseIcon from '@mui/icons-material/Close';

const PdfGenerator = ({
  ProcessDataListForParticipantsVersion2,
  setShowPdfGenerator,
}: {
  ProcessDataListForParticipantsVersion2: Array<CoachingSessionReport>;
  setShowPdfGenerator: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { toPDF, targetRef } = usePDF({ filename: 'Report.pdf' });

  const closeOverlay = () => {
    setShowOverlay(false);
    setShowPdfGenerator(false);
  };
  const [showOverlay, setShowOverlay] = useState(true);

  const [lastName, setLastName] = useState(sessionStorage.getItem('last_name'));
  const [userName, setUserName] = useState(
    sessionStorage.getItem('first_name')
  );
  return (
    <>
      {showOverlay && (
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              m: 2,
            }}
          >
            <MuiButton onClick={closeOverlay}>
              <CloseIcon />
            </MuiButton>
            <Button
              sx={{
                backgroundColor: '#1206f9',
                padding: 2,
                textAlign: 'center',
                size: 'small',
                borderRadius: '25px',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#1206f9',
                },
              }}
              id='btnForgotPassword'
              variant='contained'
              onClick={() => toPDF()}
              label='Download'
            />
          </Box>

          <div
            ref={targetRef}
            style={{ width: '210mm', minHeight: '297mm', padding: '10px' }}
          >
            <Box sx={{ width: '100%', height: '20px' }} />

            <img
              alt='mirro-logo-with-name'
              src='./comonImg/mirro-logo-with-namee.png'
              style={{
                width: '121.586px',
                height: '20.264px',
                margin: '20px auto',
                display: 'block',
              }}
            />

            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              <Typography
                sx={{ color: 'black', display: 'flex', gap: '10px', ml: 2 }}
                label='Media ID/Name:'
              >
                <Typography
                  sx={{ color: 'black' }}
                  label={`${userName} ${lastName}`}
                />
              </Typography>
              <Typography
                sx={{ color: 'black', display: 'flex', gap: '10px', ml: 2 }}
                label='Session ID:'
              >
                <Typography
                  sx={{ color: 'black' }}
                  label={`${ProcessDataListForParticipantsVersion2[0]?.coaching_session_reports_id}`}
                />
              </Typography>
            </div>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
                <Typography
                  sx={{ color: 'black', fontSize: '14px', fontWeight: 500 }}
                  label='Performance Stats'
                />
                <Paper elevation={2}>
                  <PerformanceStatGraph
                    ProcessDataListForParticipantsVersion2={
                      ProcessDataListForParticipantsVersion2
                    }
                  />
                </Paper>
              </Grid>

              <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
                <Typography
                  sx={{
                    color: 'black',
                    fontSize: '14px',
                    fontWeight: 500,
                    alignSelf: 'left',
                  }}
                  label='Tone Stats'
                />
                <Paper elevation={2}>
                  <ToneStatGraph
                    ProcessDataListForParticipantsVersion2={
                      ProcessDataListForParticipantsVersion2
                    }
                  />
                </Paper>
              </Grid>
            </Grid>

            <div style={{ margin: '20px 0', pageBreakInside: 'avoid' }}>
              <Box sx={{ padding: '20px' }}>
                <Typography
                  sx={{ color: 'black', fontSize: '14px', fontWeight: 600 }}
                  label='Overall Summary'
                />
                <Typography
                  sx={{
                    color: 'black',
                    fontSize: '12px',
                    lineHeight: '24px',
                    fontWeight: 'normal',
                  }}
                  label=''
                >
                  <pre
                    style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
                  >
                    {ProcessDataListForParticipantsVersion2[0].overall_summary}
                  </pre>
                </Typography>
              </Box>

              <Box sx={{ padding: '20px', marginTop: '5%' }}>
                <Typography
                  sx={{ color: 'black', fontSize: '14px', fontWeight: 600 }}
                  label='Prompt-wise Summary'
                />
                {ProcessDataListForParticipantsVersion2.length > 0 &&
                  ProcessDataListForParticipantsVersion2[0]?.prompt_sections.map(
                    (prompt: any, index: number) => (
                      <div key={index} style={{ pageBreakInside: 'avoid' }}>
                        <Typography
                          sx={{ display: 'flex', alignItems: 'center' }}
                          label=''
                        >
                          <Typography
                            variant='h5'
                            sx={{
                              color: 'black',
                              fontSize: '12px',
                              lineHeight: '24px',
                              fontWeight: 600,
                            }}
                            label={`Prompt ${index + 1}`}
                          />
                          :
                          <Typography
                            sx={{ fontSize: '12px', fontWeight: 400 }}
                            label={prompt.prompt_question}
                          />
                        </Typography>
                        <Typography
                          variant='h5'
                          sx={{
                            color: 'black',
                            fontSize: '12px',
                            lineHeight: '24px',
                            fontWeight: 600,
                            mt: 1,
                          }}
                          label=''
                        >
                          Summary:
                        </Typography>
                        <Typography
                          sx={{
                            color: 'black',
                            fontSize: '12px',
                            lineHeight: '24px',
                            fontWeight: 'normal',
                          }}
                          label=''
                        >
                          <pre
                            style={{
                              whiteSpace: 'pre-wrap',
                              wordWrap: 'break-word',
                            }}
                          >
                            {prompt?.overall_summary}
                          </pre>
                        </Typography>
                      </div>
                    )
                  )}
              </Box>
            </div>
          </div>
        </Box>
      )}
    </>
  );
};

export default PdfGenerator;
