/** @format */

import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import AuthWrapper from 'shared/AuthWrapper/AuthWrapper';
import './upload.css';
import { Paper, useMediaQuery } from '@mui/material';
import TextField from 'widgets/TextField/TextField';
import { UploadFiletypeList } from 'constants/UploadMedia';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  getSessionTypesServiceApi,
  uploadVideoByLinkServiceApi,
} from 'services/UploadMedia-services';
import {
  getSessionTypesSelector,
  getSubscriptionSelector,
  uploadVideoByLinkSelector,
} from 'redux/selectors/selectors';
import Loader from 'widgets/Loader/Loader';
import useSubscription from 'hooks/useSubscription';
import { UploadFiletypes, UploadMediaData } from 'types/Upload-types';
import SubscriptionStatusMessageModal from 'widgets/SubscriptionStatusMessageModal/SubscriptionStatusMessageModal';
import { subscriptionsArrayStatus } from 'constants/Subscriptions';
import { Formik, Form, useFormik } from 'formik';
import * as Yup from 'yup';
import { Toast } from 'widgets/Toast/Toast';
import UploadWithLink from './UploadWithLink';
import UploadWithDragDrop from './UploadWithDragDrop';
import ConfirmationModal from 'widgets/ConfirmationModal/ConfirmationModal';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import Autocomplete from 'widgets/AutoComplete/AutocompleteComponent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IncrementDecrementBy64 from './IncrementDecrementBy64';

const validationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    videoLink: Yup.string()
      .required('Video link is required')
      .matches(
        /(?:youtube\.com|youtu\.be)/i,
        t('upload.InvalidYoutubeVideoLink')
      ),
    videoDesc: Yup.string().required(t('upload.VideoDescriptionIsRequired')),
  });
function UploadComponent() {
  const [componentToShow, setComponentToShow] = useState<string>('upload');
  const [videoLinkIdForIframe, setVideoLinkIdForIframe] = useState<string>('');

  const [selectedSessionType, setSelectedSessionType] = useState<{
    id: number;
    name: string;
  }>({
    id: 3,
    name: 'Casual Session',
  });

  const [selectedMediaType, setSelectedMediaType] = useState<UploadFiletypes>({
    label: 'upload.VideoAudio',
    id: 1,
  });
  const [outputPeriodMsec, setOutputPeriodMsec] = useState(3072);

  const { t } = useTranslation();
  const { userSubscription } = useSubscription();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');
  const { isLoadingGetSubscription } = useAppSelector(getSubscriptionSelector);
  const dispatch = useAppDispatch();
  const { isFeatureEnable } = useFeatureFlag();
  const formik = useFormik({
    initialValues: { videoLink: '', videoDesc: '' },
    validationSchema: validationSchema,
    onSubmit: (values) => handleUploadVideoByLink(values),
  });

  const { isLoadingUploadVideoByLink } = useAppSelector(
    uploadVideoByLinkSelector
  );

  const { SessionTypes } = useAppSelector(getSessionTypesSelector);

  const [openUploadSuccessfulModal, setOpenUploadSuccessfulModal] =
    useState(false);

  useEffect(() => {
    dispatch(getSessionTypesServiceApi(-1));
  }, [dispatch]);

  function handleOpenUploadSuccessfulModal() {
    setOpenUploadSuccessfulModal(true);
  }
  function handleCloseUploadSuccessfulModal() {
    setOpenUploadSuccessfulModal(false);
  }

  const handleUploadVideoByLink = (values: {
    videoLink: string;
    videoDesc: string;
  }) => {
    const payload = {
      ...values,
      sessionId: selectedSessionType.id,
    };

    dispatch(
      uploadVideoByLinkServiceApi(payload, handleOpenUploadSuccessfulModal)
    );
    formik.resetForm();
    setVideoLinkIdForIframe('');
  };

  const matchingSubscription = subscriptionsArrayStatus.find(
    (subscriptionStatus) => subscriptionStatus.status === userSubscription
  );

  const handleSessionChange = useCallback(
    (
      _event: SyntheticEvent<Element, Event>,
      newValue: { id: number; name: string } | null
    ) => {
      if (newValue) setSelectedSessionType(newValue);
    },
    []
  );
  const handleMediaTypeChange = useCallback(
    (_event: SyntheticEvent<Element, Event>, Value: UploadFiletypes | null) => {
      if (Value) {
        setSelectedMediaType(Value);
        Value.id === 1
          ? setComponentToShow('upload')
          : setComponentToShow('ytlink');
      }
    },
    []
  );

  return (
    <AuthWrapper>
      {isLoadingUploadVideoByLink || isLoadingGetSubscription ? (
        <Loader />
      ) : (
        <div className='UploadComponentLayout'>
          <Formik
            initialValues={{ videoLink: '', videoDesc: '' }}
            validationSchema={validationSchema(t)}
            onSubmit={(values) => handleUploadVideoByLink(values)}
          >
            {({ errors, touched, values, handleChange, setFieldValue }) => (
              <Form>
                <div>
                  <p className='UploadComponentChild'>
                    {t('upload.UploadMedia')}
                  </p>
                </div>
                <div>
                  <Paper elevation={2} className='UploadComponentCard'>
                    <div
                      className='UploadItemsWrap'
                      style={{
                        flexDirection: isMobile || isTablet ? 'column' : 'row',
                      }}
                    >
                      <Autocomplete
                        id='combo-box-media-types'
                        clearIcon={false}
                        optionLabelKey='label'
                        defaultValue={{ id: 1, label: 'upload.VideoAudio' }}
                        options={
                          isFeatureEnable('youtubeupload')
                            ? UploadFiletypeList
                            : UploadFiletypeList.filter((item) => item.id !== 2)
                        }
                        getOptionLabel={(option: UploadFiletypes) =>
                          t(option.label)
                        }
                        sx={{
                          width: isMobile || isTablet ? '75%' : '30%',
                          p: 2,
                          fontFamily: 'Poppins',
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('upload.MediaType')}
                            sx={{ width: '100%' }}
                          />
                        )}
                        fullWidth
                        popupIcon={
                          <ExpandMoreIcon
                            style={{
                              color: 'blue',
                              backgroundColor: 'rgba(18, 6, 249, 0.07)',
                              borderRadius: '50%',
                            }}
                          />
                        }
                        value={selectedMediaType}
                        onChange={handleMediaTypeChange}
                        label='upload.MediaType'
                      />

                      <Autocomplete
                        id='combo-box-session-types'
                        options={SessionTypes}
                        label={t('upload.SessionType')}
                        value={selectedSessionType}
                        optionLabelKey='name'
                        getOptionLabel={(option: UploadMediaData) =>
                          option.name
                        }
                        sx={{
                          width: isMobile || isTablet ? '75%' : '30%',
                          p: 2,
                          fontFamily: 'Poppins',
                        }}
                        onChange={handleSessionChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=''
                            sx={{ width: '100%' }}
                          />
                        )}
                      />
                      {(window.location.href.includes('mirro-personal-ci') ||
                        window.location.href.includes('localhost')) && (
                        <IncrementDecrementBy64
                          count={outputPeriodMsec}
                          setCount={setOutputPeriodMsec}
                        />
                      )}
                    </div>

                    {componentToShow === 'ytlink' && (
                      <UploadWithLink
                        setVideoLinkIdForIframe={setVideoLinkIdForIframe}
                        values={values}
                        handleChange={handleChange}
                        touched={touched}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        videoLinkIdForIframe={videoLinkIdForIframe}
                        setSelectedSessionType={setSelectedSessionType}
                      />
                    )}

                    {componentToShow === 'upload' && (
                      <UploadWithDragDrop
                        selectedSessionType={selectedSessionType}
                        outputPeriodMsec={outputPeriodMsec}
                      />
                    )}
                  </Paper>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}

      {matchingSubscription && (
        <SubscriptionStatusMessageModal
          message={t(matchingSubscription.message)}
        />
      )}

      <ConfirmationModal
        message={t('upload.SavedSuccessfully')}
        openConfirmationModal={openUploadSuccessfulModal}
        handleCloseConfirmationModal={handleCloseUploadSuccessfulModal}
      />
    </AuthWrapper>
  );
}

export default UploadComponent;
